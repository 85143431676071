/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import {
  Pagination,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';

const DataTablePagination = ({
  paginationLoading,
  page,
  pages,
  pageSizeOptions,
  showPageSizeOptions,
  showPageJump,
  defaultPageSize,
  onPageChange,
  onPageSizeChange,
}) => {
  const [pageState, setPageState] = useState(0);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  useEffect(() => {
    setPageState(page);
  }, [page]);

  const getSafePage = (_page) => {
    let p = _page;
    if (Number.isNaN(_page)) {
      p = page;
    }
    return Math.min(Math.max(p, 0), pages - 1);
  };

  const changePageSize = (size) => {
    onPageSizeChange(size);
    setPageSize(size);
  };

  const changePage = (_page) => {
    const p = getSafePage(_page);
    setPageState(p);
    onPageChange(p);
  };

  const renderPageJump = () => {
    const pageNumbers = [];
    for (let i = 0; i < pages; i += 1) {
      pageNumbers.push(
        <DropdownItem key={i} onClick={() => changePage(i)}>
          {i + 1}
        </DropdownItem>
      );
    }
    return pageNumbers;
  };

  const handleChange = (data) => {
    const p = getSafePage(data.selected);
    // setPageNum(p)
    setPageState(p);
    onPageChange(p);
  };

  return (
    <div
      className={`container text-center d-flex justify-content-between align-items-center ${
        paginationLoading ? 'hide' : ''
      }`}
    >
      {showPageJump && (
        <div>
          <span>Go to Page : </span>
          <UncontrolledDropdown className="d-inline-block">
            <DropdownToggle caret outline size="xs">
              {pageState + 1}
            </DropdownToggle>
            <DropdownMenu
              direction="left"
              style={{ maxHeight: '200px' }}
              className="overflow-auto"
            >
              {renderPageJump()}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      )}
      <Pagination
        className="d-inline mx-4"
        size="sm"
        aria-label="Page navigation example"
      >
        <ReactPaginate
          forcePage={pageState}
          pageCount={pages}
          breakLabel={'.....'}
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          onPageChange={handleChange}
          containerClassName={'pagination'}
          pageClassName={'page-item'}
          pageLinkClassName={'page-link'}
          previousClassName={'page-item'}
          previousLinkClassName={'page-link'}
          nextClassName={'page-item'}
          nextLinkClassName={'page-link'}
          breakClassName={'page-item'}
          breakLinkClassName={'page-link'}
          activeClassName={'active'}
        />
      </Pagination>

      <div>
        <span className="mr-1">Go to Item : </span>
        <UncontrolledDropdown className="d-inline-block">
          <DropdownToggle caret outline size="xs">
            {pageSize}
          </DropdownToggle>
          <DropdownMenu
            right
            style={{ maxHeight: '200px' }}
            className="overflow-auto"
          >
            {pageSizeOptions.map((size, index) => {
              return (
                <DropdownItem key={index} onClick={() => changePageSize(size)}>
                  {size}
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </div>
  );
};
export default DataTablePagination;
