import { PopoverHeader, PopoverBody, UncontrolledPopover } from 'reactstrap';

export const TyposList = (index, variants) => {
  return (
    <UncontrolledPopover
      trigger="hover"
      placement="right"
      target={'typoDetailsPopover-' + Number(index)}
    >
      <PopoverBody>
        {variants?.length > 0 ? (
          <ul style={{ listStyle: 'none' }}>
            {variants.map((v) => (
              <li>{v}</li>
            ))}
          </ul>
        ) : (
          'No Typos'
        )}
      </PopoverBody>
    </UncontrolledPopover>
  );
};
