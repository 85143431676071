import axios from "axios";
import { getCookie } from "../helpers/cookie";
import { getAPIURL } from "./utils";
const url = getAPIURL();

// get all Stores
export const getAllStores = async () => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${url}/api/stores`, config);

  return response;
};

// get Store by UserId
export const getStoreByUserId = async (id) => {
  const token = getCookie();
  const data = { _id: id };

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${url}/api/stores/getStoreByUserId`,
    data,
    config
  );
  console.log("response: ", response);
  return response;
};

export const getStoresByAccountId = async ({
  accountId,
  limit,
  page,
  searchValue,
  sorting,
}) => {
  const token = getCookie();
  const params = new URLSearchParams();
  params.append("accountId", accountId?.toString());
  params.append("limit", limit?.toString());
  params.append("page", page?.toString());
  params.append("search", searchValue?.toString().trim());
  params.append("sorting", sorting?.toString().trim());

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    params,
  };

  const response = await axios.get(
    `${url}/api/stores/getStoreByAccount`,
    config
  );

  return response;
};
