import axios from "axios";
import { getCookie } from "../helpers/cookie";
import { getAPIURL } from "./utils";
const url = getAPIURL();

// createCities
export const createCities = async (cities) => {
  const token = getCookie();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  let response;
  try {
    response = await axios.post(
      `${url}/api/cities/addCities`,
      { cities },
      config
    );
  } catch (e) {
    response = e;
  }

  return response;
};

// get All Cities
export const getAllCities = async ({ limit, page, searchValue, sorting }) => {
  let token = getCookie();
  const params = new URLSearchParams();
  params.append("limit", limit?.toString());
  params.append("page", page?.toString());
  params.append("search", searchValue?.toString().trim());
  params.append("sorting", sorting.toString());

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  };
  let response;
  try {
    response = await axios.get(`${url}/api/cities/getAllCities`, config);
  } catch (error) {
    response = String(error);
  }
  return response;
};

// Update City
export const updateCityName = async (newCity) => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${url}/api/cities/updateCity`,
    newCity,
    config
  );
  return response;
};

// Delete City

export const deleteCity = async (cityId) => {
  const token = getCookie();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  let response;
  try {
    response = await axios.delete(
      `${url}/api/cities/deleteCity/${cityId}`,
      config
    );
  } catch (e) {
    response = e;
  }

  return response;
};
