import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Card, CardBody, Breadcrumb } from 'reactstrap';
import { Cols } from '../SeparatorStyle/SeparatorStyle';
const Dashboard = () => {
  return (
    <main>
      <Row className="mb-5 marginL-md dashboardHead">
        <div className="px-4 d-flex justify-content-between mb-4 flex-wrap dashboardName">
          <div className="me-3">
            <h1>Dashboard</h1>
          </div>
          <div className=" float-end dashboardFilter-xs">
            <Breadcrumb>
              <ol className="breadcrumb pt-0">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" style={{ color: 'black' }}>
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Data
                </li>
              </ol>
            </Breadcrumb>
          </div>
        </div>
      </Row>
      {
        // (!allOrdersLoading) && (!pendingOrdersLoading) && (!fulfilledOrdersLoading) && (!refundedOrdersLoading) ?
        <Row className=" marginL-sm marginL-md">
          <Cols xxs="12" className="row icon-cards-row mb-2">
            <Cols xxs="12" sm="6" md="4" lg="3" className="mb-4">
              <Card>
                <CardBody className="text-center">
                  <i className="iconsminds-basket-coins" />
                  <p className="card-text font-weight-semibold mb-0">
                    Total Orders
                  </p>
                  <p className="lead text-center">Orders</p>
                </CardBody>
              </Card>
            </Cols>
            <Cols xxs="12" sm="6" md="4" lg="3" className="mb-4">
              <Card>
                <CardBody className="text-center">
                  <i className="iconsminds-mail-read" />
                  <p className="card-text font-weight-semibold mb-0">
                    Completed Orders
                  </p>
                  <p className="lead text-center">fullfilled Orders</p>
                </CardBody>
              </Card>
            </Cols>
            <Cols xxs="12" sm="6" md="4" lg="3" className="mb-4">
              <Card>
                <CardBody className="text-center">
                  <i className="iconsminds-clock" />
                  <p className="card-text font-weight-semibold mb-0">
                    Pending Payments
                  </p>
                  <p className="lead text-center">Pending Orders</p>
                </CardBody>
              </Card>
            </Cols>
            <Cols xxs="12" sm="6" md="4" lg="3" className="mb-4">
              <Card>
                <CardBody className="text-center">
                  <i className="iconsminds-arrow-refresh" />
                  <p className="card-text font-weight-semibold mb-0">
                    Refunded Orders
                  </p>
                  <p className="lead text-center">Refunded Orders</p>
                </CardBody>
              </Card>
              {/* <button onClick={slipFunction}>
                                Click to print Slip
                            </button> */}
            </Cols>
          </Cols>
        </Row>
        // : <div className="loading" />
      }
    </main>
  );
};

export default Dashboard;
