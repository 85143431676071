import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTable, usePagination } from 'react-table';
import DatatablePagination from './Pagination';
import { Table } from 'reactstrap';

const DataTable = ({
  setPageNumberInParent,
  setPageLimitInParent,
  columns,
  data,
  pageCount: totalPages,
  divided = false,
  defaultPageSize = 50,
  fetchData,
  pagination,
  setPaginationLoading,
  paginationLoading,
  selectedLength,
}) => {
  const history = useHistory();
  const [pageNum, setPageNum] = useState(0);
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: pageNum,
        pageSize: defaultPageSize,
      },
      manualPagination: true,
      pageCount: totalPages,
    },
    usePagination
  );

  const PageChange = (p) => {
    gotoPage(p);
    setPageNum(p);
    setPageNumberInParent(p + 1);
    setPaginationLoading(true);
    window.scrollTo(0, 0);
    fetchData({
      page: p + 1,
      limit: pageSize,
    });
  };
  const PageSizeChange = (s) => {
    defaultPageSize = s;
    setPageSize(s);
    setPaginationLoading(true);
    window.scrollTo(0, 0);
    setPageLimitInParent(s);
    fetchData({
      page: pageIndex + 1,
      limit: s,
    });
  };

  const onRowClick = (e, cell) => {
    // if (pagination === 0) {
    //   const { id } = cell.row.original;
    //   history.push(`/admin/orders/${id}`);
    // } else if (cell.column.id === 'orderId') {
    //   const { id } = cell.row.original;
    //   history.push(`/admin/orders/${id}`);
    // }
  };

  return (
    <>
      <Table
        striped
        borderless
        responsive
        {...getTableProps()}
        className={
          paginationLoading ? 'data-table backgroundBlur' : 'data-table'
        }
      >
        <thead>
          {headerGroups?.map((headerGroup) => (
            <>
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers?.map((column, columnIndex) => (
                  <th
                    key={`th_${columnIndex}`}
                    // {...column.getHeaderProps(column.getSortByToggleProps())}
                    // className={
                    //   column.isSorted
                    //     ? column.isSortedDesc
                    //       ? 'sorted-desc'
                    //       : 'sorted-asc'
                    //     : ''
                    // }
                  >
                    {columnIndex === 0 && (
                      <p style={{ minHeight: '22px' }} className="text-muted">
                        {selectedLength > 0 && selectedLength}
                      </p>
                    )}
                    {column.render('Header')}
                    <span />
                  </th>
                ))}
              </tr>
            </>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page?.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} style={{ height: '50px' }}>
                {row.cells?.map((cell, cellIndex) => (
                  <td
                    key={`td_${cellIndex}`}
                    {...cell.getCellProps({
                      className: cell.column.cellClass,
                    })}
                    onClick={(e) => onRowClick(e, cell)}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {pageCount !== 0 && (
        <DatatablePagination
          paginationLoading={paginationLoading}
          page={pageIndex}
          pages={pageCount}
          canPrevious={canPreviousPage}
          canNext={canNextPage}
          pageSizeOptions={[50, 100, 150, 200, 250]}
          showPageSizeOptions={true}
          showPageJump={true}
          defaultPageSize={pageSize}
          onPageChange={(p) => PageChange(p)}
          onPageSizeChange={(s) => PageSizeChange(s)}
          paginationMaxSize={pageCount}
        />
      )}
    </>
  );
};

export default DataTable;
