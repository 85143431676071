import React, { useState, useCallback, useEffect } from "react";
import {
  Card,
  Breadcrumb,
  Button,
  Row,
  Table,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { Cols } from "../SeparatorStyle/SeparatorStyle";
import { Link } from "react-router-dom";

const Users = () => {
  const [modal, setModal] = useState(false);

  const modalToggle = () => {
    setModal(!modal);
  };

  return (
    <>
      <main>
        <Row>
          <Cols xxs="12">
            <h1>Users</h1>
            <div style={{ display: "inline" }}>
              <Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
                <ol className="breadcrumb pt-0">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard" style={{ color: "black" }}>
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    View Users
                  </li>
                </ol>
              </Breadcrumb>
            </div>
          </Cols>
        </Row>

        <Card className="mt-2 mb-3">
          <Row>
            <Cols>
              <div className="text-center">
                <Table striped borderless hover className="mt-3">
                  <thead>
                    <tr>
                      <th>Email</th>
                      <th>Stores</th>
                      <th>Accounts</th>
                      <th>Profile</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Linon@gmail.com</td>
                      <td>8</td>
                      <td>2</td>
                      <td>
                        <Button color="primary" size="xs" onClick={modalToggle}>
                          View
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <td>Apix@gmail.com</td>
                      <td>10</td>
                      <td>3</td>
                      <td>
                        <Button color="primary" size="xs" onClick={modalToggle}>
                          View
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Cols>
          </Row>
        </Card>

        <div>
          <Modal toggle={modalToggle} isOpen={modal} scrollable centered>
            <ModalHeader toggle={modalToggle}>
              <h2>
                <b>User Name</b>
              </h2>
            </ModalHeader>
            <ModalBody>
              <div>User Details and Info</div>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={modalToggle}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </main>
    </>
  );
};

export default Users;
