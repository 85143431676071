import {
  USER_COURIER_REQUEST,
  USER_COURIER_SUCCESS,
  USER_COURIER_FAIL,
} from "../constants/courierConstants";

export const userCouriersReducer = (
  state = {
    courierNames: [],
    citiesCourier: {},
    defaultCityCourier: {},
  },
  action
) => {
  switch (action.type) {
    case USER_COURIER_REQUEST:
      return { userCourierLoading: true, data: {} };
    case USER_COURIER_SUCCESS:
      return { userCourierLoading: false, data: action.payload };
    case USER_COURIER_FAIL:
      return { userCourierLoading: false, userCourierError: action.payload };

    default:
      return state;
  }
};
