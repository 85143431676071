import React, { useState, useCallback, useEffect, useMemo } from "react";
import {
  Table,
  Card,
  Button,
  Row,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Spinner,
  FormGroup,
  Label,
  Alert,
  Badge,
  UncontrolledPopover,
  PopoverBody,
  CardBody,
} from "reactstrap";
import { toast } from "react-toastify";
import { Cols } from "../SeparatorStyle/SeparatorStyle";
import DataTable from "../tables/table";
import { getAllCities, updateCityName } from "../../api/cities";
import Select from "react-select";
import { TyposList } from "./TyposList.jsx";
import {
  deleteCityTypos,
  getTyposByCity,
  saveCityTypos,
} from "../../api/cityTypos";
import { handleSearch } from "../../helpers/searchHook";

const CityTypos = () => {
  const sortOptions = [
    { value: "ascending-name", label: "Name (A-Z)" },
    { value: "descending-name", label: "Name (Z-A)" },
  ];

  const [total, setTotal] = useState("");
  const [pageLimit, setPageLimit] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [allCities, setAllCities] = useState([]);
  const [emptyStatus, setEmptyStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchTrigger, setSearchTrigger] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [sortingFilterValue, setSortingFilterValue] = useState("");

  const [typosList, setTyposList] = useState({ index: "", list: [] });
  let defaultUpdate = {
    city_id: "",
    cityTypos: [],
    label: "",
  };
  const [updateTypos, setUpdateTypos] = useState(defaultUpdate);
  const [newTypo, setNewTypo] = useState({ value: "", cityId: "" });
  const [typoLoader, setTypoLoader] = useState(false);

  const loadAllCities = useCallback(
    async ({
      limit = pageLimit,
      page = pageNumber,
      searchValue = search.trim().length > 2 ? search : "",
      sorting = sortingFilterValue ? sortingFilterValue?.value : "",
    }) => {
      try {
        const { status, data } = await getAllCities({
          limit,
          page,
          searchValue,
          sorting,
        });
        setTotal(data.count);

        if (status === 200) {
          setAllCities(data.cities);

          if (data.count === 0 && !search && !sortingFilterValue) {
            setEmptyStatus(true);
          } else {
            setEmptyStatus(false);
          }
        }

        setLoading(false);
        setFilterLoading(false);
        setPaginationLoading(false);
      } catch (err) {
        toast.error("Cities data loading error! ");
      }
    },
    [pageLimit, pageNumber, searchTrigger, sortingFilterValue]
  );

  useEffect(() => {
    loadAllCities({});
  }, [loadAllCities]);

  const totalPages = Math.ceil(total / pageLimit);

  // const toTitleCase = (str) => {
  //   return str.replace(/\w\S*/g, (txt) => {
  //     return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  //   });
  // };

  const handleshowUpdtedTypos = async (cityId) => {
    setNewTypo({ ...newTypo, value: "" });
    try {
      const { data, status } = await getTyposByCity(cityId);
      if (status === 200) {
        setUpdateTypos({ ...updateTypos, cityTypos: data });
      }
    } catch (error) {
      console.log(error);
      toast.error("Error while load typos");
    }
  };

  const handleEditTypoModel = () => {
    setUpdateTypos(defaultUpdate);
    setNewTypo({ value: "", cityId: "" });
  };

  const handleCreateTypo = async () => {
    setTypoLoader(true);
    let dataFormat = { cityId: newTypo.cityId, value: newTypo.value?.trim() };
    try {
      const { data, status } = await saveCityTypos(dataFormat);
      if (status === 200) {
        toast.success(data);
        await loadAllCities({});
        await handleshowUpdtedTypos(newTypo.cityId);
      }
      setTypoLoader(false);
    } catch (error) {
      toast.error(error.response.data || "Error While Create Typo!");
      setTypoLoader(false);
    }
  };

  const handleDeleteCityTypo = async (dataFormat) => {
    setTypoLoader(true);
    try {
      const { data, status } = await deleteCityTypos(dataFormat);
      if (status === 200) {
        toast.success(data);
        await loadAllCities({});
        await handleshowUpdtedTypos(dataFormat.cityId);
      }
      setTypoLoader(false);
    } catch (error) {
      setTypoLoader(false);
      toast.error("Error While Delete Typo");
      console.log(error.message);
    }
  };

  // const handleSort = async (e) => {
  //   setSortingFilterValue(e);

  //   let sorting = [];

  //   if (!e) {
  //     sorting = allCities.sort((a, b) => b._id.localeCompare(a._id));
  //   } else {
  //     if (e?.value.includes("-name")) {
  //       if (e.value === "ascending-name") {
  //         sorting = allCities.sort((a, b) =>
  //           a?.label.toLowerCase().localeCompare(b?.label?.toLowerCase())
  //         );
  //       } else {
  //         sorting = allCities.sort((a, b) =>
  //           b?.label?.toLowerCase()?.localeCompare(a?.label?.toLowerCase())
  //         );
  //       }
  //     }
  //   }
  //   setAllCities([...sorting]);
  // };

  const currentPageData = allCities?.map((obj, index) => {
    return {
      cityName: (
        <>
          {obj?.label.slice(0, 20)}
          {obj?.label.length > 20 && (
            <>
              {" ... "}
              <i className="simple-icon-info pointer" id={"Title-" + index}>
                <UncontrolledPopover
                  trigger="hover"
                  placement="top"
                  target={"Title-" + index}
                >
                  <PopoverBody className="text-center">
                    {obj?.label}
                  </PopoverBody>
                </UncontrolledPopover>
              </i>
            </>
          )}
        </>
      ),

      action: (
        <span
          onClick={() => {
            setUpdateTypos({
              ...updateTypos,
              cityTypos: obj.cityTypos,
              city_id: obj._id,
              label: obj.label,
            });
          }}
          style={{ cursor: "pointer" }}
          className="simple-icon-eye text-primary"
          onMouseEnter={() => {
            setTyposList({
              ...typosList,
              index,
              list: obj?.cityTypos,
            });
          }}
          onMouseLeave={() => {
            setTyposList({ index: "", list: [] });
          }}
          id={"typoDetailsPopover-" + Number(typosList?.index)}
        >
          {typosList.index === index &&
            TyposList(typosList?.index, typosList.list)}
        </span>
      ),
    };
  });

  const cols = useMemo(
    () => [
      {
        Header: "City Name",
        accessor: "cityName",
        cellClass: "w-15",
        Cell: (props) => <>{props.value}</>,
      },

      {
        Header: "Typos",
        accessor: "action",
        cellClass: "w-15",
        Cell: (props) => <>{props.value}</>,
      },
    ],
    [allCities, filterLoading, paginationLoading, searchTrigger]
  );

  return (
    <>
      <Row>
        <Cols xxs="12">
          <div>
            {loading ? (
              <div
                className=" position-relative"
                style={{
                  height: "50vh",
                }}
              >
                <span className=" position-absolute loading"></span>
              </div>
            ) : emptyStatus ? (
              <Card>
                <div
                  className=" d-flex justify-content-center align-items-center text-danger fw-bold fs-5"
                  style={{
                    height: "200px",
                  }}
                >
                  <span>No Cities Found!</span>
                </div>
              </Card>
            ) : (
              <Card>
                <CardBody>
                  <Row className="d-flex justify-content-center">
                    <Cols lg="6">
                      <div className=" mt-2 d-flex justify-content-between flex-wrap">
                        <div className="mr-2 mb-2 position-relative">
                          <Input
                            disabled={filterLoading || paginationLoading}
                            type="text"
                            name="keyword"
                            value={search}
                            id="search"
                            placeholder="Search"
                            onChange={(e) => {
                              handleSearch({
                                e,
                                setPageNumber,
                                setPageLimit,
                                setFilterLoading,
                                setSearch,
                                setSearchTrigger,
                                searchTrigger,
                              });
                            }}
                            style={{ minWidth: "200px" }}
                            className="rounded-lg"
                          />
                          {search.trim() && search.trim().length < 3 && (
                            <i
                              className="simple-icon-info pointer position-absolute text-danger fw-bold"
                              style={{ right: "-20px", bottom: "12px" }}
                              id={"SearchInfo"}
                            >
                              {" "}
                              <UncontrolledPopover
                                trigger="hover"
                                placement="top"
                                target={"SearchInfo"}
                              >
                                <PopoverBody className="text-center">
                                  <i>Minimum 3 words required!</i>
                                </PopoverBody>
                              </UncontrolledPopover>
                            </i>
                          )}
                          {search &&
                            (filterLoading && search.trim().length > 2 ? (
                              <Spinner
                                color="primary"
                                size={"sm"}
                                style={{ right: "10px", bottom: "12px" }}
                                className=" position-absolute"
                                type="grow"
                              ></Spinner>
                            ) : (
                              <span
                                className="far fa-close fs-6 position-absolute text-danger"
                                style={{
                                  right: "10px",
                                  bottom: "12px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  if (search) {
                                    setSearch("");

                                    setSearchTrigger(!searchTrigger);
                                    setFilterLoading(true);
                                  }
                                  pageNumber > 1 && setPageNumber(1);
                                  pageLimit > 50 && setPageLimit(50);
                                }}
                              ></span>
                            ))}
                        </div>

                        <div className="d-flex">
                          <div style={{ minWidth: "153px" }} className="mr-3">
                            <Select
                              hideSelectedOptions
                              isDisabled={filterLoading || paginationLoading}
                              isClearable
                              value={sortingFilterValue}
                              onChange={(e) => {
                                setPaginationLoading(true);
                                if (e) {
                                  setSortingFilterValue(e);

                                  // handleSort(e);
                                } else {
                                  setSortingFilterValue("");

                                  // handleSort("");
                                }
                              }}
                              options={sortOptions}
                              placeholder="Sort Filters"
                            />
                          </div>
                        </div>
                      </div>
                      {paginationLoading && (
                        <div className="mt-3 mx-3">
                          <Alert color="info">
                            <Spinner
                              color="light"
                              size={"sm"}
                              style={{ marginBottom: "3px" }}
                            ></Spinner>{" "}
                            &nbsp;
                            <span style={{ fontSize: "16px", color: "black" }}>
                              Cities Loading!
                            </span>
                          </Alert>
                        </div>
                      )}

                      {filterLoading ? (
                        <div
                          className=" position-relative"
                          style={{
                            height: "200px",
                          }}
                        >
                          <span className=" position-absolute loading"></span>
                        </div>
                      ) : allCities?.length === 0 ? (
                        <div
                          className=" d-flex justify-content-center align-items-center text-danger"
                          style={{
                            height: "200px",
                          }}
                        >
                          <h3>
                            <b>No Cities Matched!</b>
                          </h3>
                        </div>
                      ) : (
                        <DataTable
                          setPageLimitInParent={setPageLimit}
                          setPageNumberInParent={setPageNumber}
                          fetchData={loadAllCities}
                          columns={cols || []}
                          data={currentPageData || []}
                          pageCount={totalPages}
                          setPaginationLoading={setPaginationLoading}
                          paginationLoading={paginationLoading}
                          selectedLength={0}
                        />
                      )}
                    </Cols>
                  </Row>
                </CardBody>
              </Card>
            )}
          </div>
          <Modal isOpen={updateTypos?.city_id}>
            <ModalHeader toggle={handleEditTypoModel}>
              <b>{updateTypos?.label} Typos List</b>
            </ModalHeader>
            {typoLoader ? (
              <div
                className=" position-relative"
                style={{
                  height: "260px",
                }}
              >
                <span className=" position-absolute loading"></span>
              </div>
            ) : (
              <>
                <ModalBody>
                  <div className="container px-5">
                    {updateTypos?.cityTypos?.length === 0 ||
                    updateTypos?.cityTypos?.length === undefined ? (
                      <div
                        className=" d-flex justify-content-center align-items-center text-danger font-weight-bold"
                        style={{
                          height: "160px",
                          fontSize: "16px",
                        }}
                      >
                        <span>No Typos Found!</span>
                      </div>
                    ) : (
                      <ul>
                        {updateTypos?.cityTypos.map((v, i) => (
                          <li key={i}>
                            <div className="d-flex mb-3 justify-content-between flex-nowrap align-items-center">
                              <span>{v}</span>
                              <Button
                                size="xs"
                                color="danger"
                                onClick={() => {
                                  handleDeleteCityTypo({
                                    cityId: updateTypos.city_id,
                                    value: v,
                                  });
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </ModalBody>
                <ModalFooter className="justify-content-center">
                  {newTypo.cityId ? (
                    <>
                      <Input
                        value={newTypo.value}
                        className="rounded-lg mb-3"
                        type="text"
                        placeholder="Create Typo"
                        onChange={(e) => {
                          setNewTypo({ ...newTypo, value: e.target.value });
                        }}
                      />
                      {newTypo.value.trim() && (
                        <Button
                          size="xs"
                          color="primary"
                          onClick={() => {
                            handleCreateTypo();
                          }}
                        >
                          Create
                        </Button>
                      )}
                      <Button
                        size="xs"
                        onClick={() => {
                          setNewTypo({ value: "", cityId: "" });
                        }}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        size="sm"
                        color="primary"
                        onClick={() => {
                          setNewTypo({
                            ...newTypo,
                            cityId: updateTypos?.city_id,
                          });
                        }}
                      >
                        Add New
                      </Button>{" "}
                      <Button
                        color="secondary"
                        size="sm"
                        onClick={handleEditTypoModel}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                </ModalFooter>
              </>
            )}
          </Modal>
        </Cols>
      </Row>
    </>
  );
};

export default CityTypos;
