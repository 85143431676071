import axios from "axios";
import { getCookie } from "../helpers/cookie";
import { getAPIURL } from "./utils";
const url = getAPIURL();

export const getAnywayCities = async ({
  limit,
  page,
  searchValue,
  sorting,
}) => {
  let token = getCookie();

  const params = new URLSearchParams();
  params.append("limit", limit?.toString());
  params.append("page", page?.toString());
  params.append("search", searchValue?.toString().trim());
  params.append("sorting", sorting.toString());

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  };

  const response = await axios.get(
    `${url}/api/anywayCities/getAnyWayCitiesList`,
    config
  );

  return response;
};

export const saveAnywayCity = async (cityName) => {
  const token = getCookie();
  const data = { cityNames: cityName };
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  let response = await axios.post(`${url}/api/anywayCities/`, data, config);

  return response;
};

export const addAnyWayCityIntoCities = async (data) => {
  const token = getCookie();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  let response;

  response = await axios.post(
    `${url}/api/anywayCities/addIntoCities`,
    data,
    config
  );

  return response;
};

export const deleteAnywayCity = async (cityId) => {
  const token = getCookie();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  let response;

  response = await axios.delete(
    `${url}/api/anywayCities/deleteAnywayCity/${cityId}`,
    config
  );

  return response;
};

export const updateAnywayCityName = async (newCity) => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${url}/api/anywayCities/updateAnywayCity`,
    newCity,
    config
  );
  return response;
};
