import React from 'react';

import {
  Row,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Breadcrumb,
} from 'reactstrap';
import { Cols } from '../SeparatorStyle/SeparatorStyle';
import { Link } from 'react-router-dom';

const UserAndPermissions = () => {
  return (
    <>
      <main>
        <Row className="top-text pt-3 mb-5">
          <Cols xxs="12">
            <h1>Users and Permissions</h1>
            <div style={{ display: 'inline' }}>
              <Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
                <ol className="breadcrumb pt-0">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard" style={{ color: 'black' }}>
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    User and Permissions
                  </li>
                </ol>
              </Breadcrumb>
            </div>
          </Cols>
          <b className="ml-4">Manage what User can see or do in your Store</b>
        </Row>
        <hr />
        <Row className="pt-3">
          <Cols xxs="12" className="row icon-cards-row mb-2 ps-0">
            <Cols className="mb-4">
              <Card className=" marginL-md">
                <CardBody className="m-2">
                  <CardTitle tag="h6">
                    <b>Staff Permissions for Specific Apps and Staff</b>
                  </CardTitle>
                  <CardText>
                    Select which apps and channels your staff members can
                    access, install and manage.
                  </CardText>
                </CardBody>
              </Card>
            </Cols>
          </Cols>
        </Row>
        <Row>
          <Cols xxs="12" className="row icon-cards-row mb-2 ps-0">
            <Cols className="mb-4">
              <Link to="/admin/account/personalSetting" className="ml-3">
                <Card className=" marginL-md">
                  <CardBody className="m-2">
                    <div>
                      <CardTitle tag="h5">
                        <b>Store Owner</b>
                      </CardTitle>
                      <div style={{ display: 'inline' }}>
                        <img
                          src="demo.png"
                          alt="pic"
                          style={{ height: '40px', width: '40px' }}
                        ></img>
                      </div>
                      <div style={{ display: 'inline', marginLeft: '10px' }}>
                        <b>{'Admin'}</b>
                        <CardText className="ml-5">
                          Last Login was {'Today'}
                        </CardText>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Link>
            </Cols>
          </Cols>
        </Row>
      </main>
    </>
  );
};

export default UserAndPermissions;
