import { useState, useCallback, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import React from 'react';
import {
  getUserData,
  updateMyEmail,
  updateMyPassword,
  updateMyProfile,
} from '../../api/auth';
import isEmail from 'validator/lib/isEmail';
import isEmpty from 'validator/lib/isEmpty';
import equals from 'validator/lib/equals';
import {
  InfoMessage,
  WarningMessage,
  ErrorMessage,
} from '../Messages/message.jsx';
import {
  Row,
  Card,
  CardBody,
  Label,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Breadcrumb,
  Button,
  Spinner,
} from 'reactstrap';
import { Cols } from '../SeparatorStyle/SeparatorStyle';
// import SaveMultipleStores from '../store/SaveMultipleStores.jsx';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { userInfo, userImage } from '../../redux/actions/authActions';
// import { userInfo, userImage } from '../../redux/actions/authActions';

import { toast } from 'react-toastify';
import { logout } from '../../helpers/auth';

const AccountSetting = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState('');
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [currentEmail, setCurrentEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [image, setImage] = useState();
  const [base64Image, setBase64Image] = useState();
  const [modal, setModal] = useState(false);
  const [language, setLanguage] = useState('English');
  const [country, setCountry] = useState('Pakistan');
  const [region, setRegion] = useState('Punjab');
  const [timezone, setTimezone] = useState('Islamabad,Karachi (GMT+05: 00)');
  const [showButtons, setShowButtons] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [warningMsg, setWarningMsg] = useState('');
  const [infoMsg, setInfoMsg] = useState('');
  const [modalErrorMsg, setModalErrorMsg] = useState('');
  const [modalWarningMsg, setModalWarningMsg] = useState('');
  const [modalInfoMsg, setModalInfoMsg] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [emailModal, setEmailModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [defaultImage, setDefaultImage] = useState(true);
  const [removeButtonDisable, setRemoveButtonDisable] = useState(false);

  const langOptions = [{ label: 'English', value: 'english' }];
  const countryOptions = [{ label: 'Pakistan', value: 'pakistan' }];
  const regionOptions = [{ label: 'Punjab', value: 'punjab' }];
  const timezoneOptions = [
    {
      label: 'Islamabad,Karachi (GMT+05: 00)',
      value: 'Islamabad,Karachi (GMT+05: 00)',
    },
  ];
  const imageRedux = useSelector((state) => state.userImage);
  

  const handleGetCurrentUser = useCallback(async () => {
    try {
      const { data } = await getUserData();
      const {
        firstname,
        lastname,
        email,
        phone,
        selectedImage,
        username,
        image,
      } = data.user;
      setCurrentUser(data.user);
      setFirstName(firstname);
      setUserName(username);
      setLastName(lastname);
      setEmail(email);
      setCurrentEmail(email);
      setPhone(phone);
      setImage(selectedImage);
      setBase64Image(image);
    } catch (error) {
      toast.error('Fetching User Data Failed!');
    }
  }, []);

  useEffect(() => {
    if (base64Image === null) {
      setRemoveButtonDisable(true);
    } else {
      setRemoveButtonDisable(false);
    }
  }, []);

  const handleLanguage = (e) => {
    setLanguage(e.label);
  };

  const handleCountry = (e) => {
    setCountry(e.label);
  };

  const handleRegion = (e) => {
    setRegion(e.label);
  };

  const handleTimezone = (e) => {
    setTimezone(e.label);
  };

  useEffect(() => {
    handleGetCurrentUser();
  }, [handleGetCurrentUser]);

  const setNotificationMsg = () => {
    setInfoMsg('');
    setErrorMsg('');
    setWarningMsg('');
    setModalInfoMsg('');
    setModalErrorMsg('');
    setModalWarningMsg('');
  };

  const handleResetFields = () => {
    setDefaultImage(true);
    setNotificationMsg();
    setFirstName(firstname);
    setLastName(lastname);
    setUserName(userName);
    setPhone(phone);
    setEmail(email);
    setShowButtons(false);
    setBase64Image(imageRedux);
  };

  const handleEmailToggle = () => {
    setEmailModal(!emailModal);
    setNotificationMsg();
    setEmail(currentEmail);
    setOldPassword('');
  };

  const handlePasswordToggle = () => {
    setPasswordModal(!passwordModal);
    setNotificationMsg();
    setOldPassword('');
    setPassword('');
    setConfirmPassword('');
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      function getBase64(file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          if (reader.result.length > 135550) {
            toast.error('Please Add Image Under 100KB');
            setBase64Image(imageRedux);
          } else {
            setDefaultImage(true);
            setBase64Image(reader.result);
          }
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
      }
      getBase64(e.target.files[0]);
    }

    setInfoMsg('');
    setErrorMsg('');
    setWarningMsg('');
    setModalInfoMsg('');
    setModalErrorMsg('');
    setModalWarningMsg('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEmpty(firstname)) {
      setInfoMsg('Please Enter First Name');
    } else if (isEmpty(lastname)) {
      setInfoMsg('Please Enter Last Name!');
    } else if (isEmpty(userName)) {
      setInfoMsg('Please Enter UserName!');
    } else if (defaultImage === false) {
      setShowButtons(false);
      dispatch(userInfo(userName));
      dispatch(userImage('img/profiles/l-1.png'));
      updateMyProfile(
        firstname.trim(),
        lastname.trim(),
        phone?.trim(),
        setBase64Image('img/profiles/l-1.png'),
        userName.trim()
      )
        .then((response) => {
          toast.success('Your Profile is Updated Successfully!');
        })
        .catch((error) => {
          setErrorMsg(error.response.data.errorMessage);
        });
    } else {
      setShowButtons(false);
      dispatch(userInfo(userName));
      dispatch(userImage(base64Image));
      updateMyProfile(
        firstname.trim(),
        lastname.trim(),
        phone?.trim(),
        base64Image,
        userName.trim()
      )
        .then((response) => {
          toast.success('Your Profile is Updated Successfully!');
        })
        .catch((error) => {
          setErrorMsg(error.response.data.errorMessage);
        });
    }
  };

  const handleRemoveSelectedImage = () => {
    setShowButtons(true);
    setDefaultImage(false);
    setImage();
    setInfoMsg('');
    setErrorMsg('');
    setWarningMsg('');
    setModalInfoMsg('');
    setModalErrorMsg('');
    setModalWarningMsg('');
  };

  // Update Email
  const handleUpdateEmail = (e) => {
    e.preventDefault();
    if (isEmpty(oldPassword)) {
      setModalWarningMsg('Please Enter Your Current Password');
    } else if (isEmpty(email)) {
      setModalWarningMsg('Please Enter Email!');
    } else if (!isEmail(email)) {
      setModalErrorMsg('Email is invalid');
    } else if (currentUser.email === email) {
      setModalInfoMsg('Please Change Email to Update!');
    } else {
      setNotificationMsg();
      updateMyEmail(email, oldPassword)
        .then((response) => {
          setEmailModal(false);
          toast.success('Email is Updated Successfully!');
          toast.info('Please Login Again!');
          logout(() => {
            history.push('/signin');
          });
        })
        .catch((error) => {
          setModalErrorMsg(error.response.data.errorMessage);
        });
    }
  };

  // Update Password
  const handleUpdatePassword = (e) => {
    e.preventDefault();
    if (isEmpty(oldPassword)) {
      setModalWarningMsg('Please Enter Old Password!');
    } else if (isEmpty(password)) {
      setModalWarningMsg('Please Enter New Password!');
    } else if (isEmpty(confirmPassword)) {
      setModalWarningMsg('Please Confirm New Password!');
    } else if (!equals(password, confirmPassword)) {
      setModalErrorMsg("New Passwords Don't Match");
    } else if (equals(oldPassword, password, confirmPassword)) {
      setModalInfoMsg('New Password Must be Different from Old Password');
    } else {
      setNotificationMsg();
      updateMyPassword(oldPassword, password)
        .then((response) => {
          toast.success('Password Updated Successfully!');
          setPasswordModal(false);
        })
        .catch((error) => {
          setModalErrorMsg(error.response.data.errorMessage);
        });
    }
  };

  return (
    <>
      <main>
        <Row className="top-text pt-3 mb-5">
          <Cols xxs="12">
            <h1>Account Settings</h1>
            <div style={{ display: 'inline' }}>
              <Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
                <ol className="breadcrumb pt-0">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard" style={{ color: 'black' }}>
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Account Settings
                  </li>
                </ol>
              </Breadcrumb>
              <>
                <Button
                  type="button"
                  className="me-4"
                  onClick={handleResetFields}
                  style={{ float: 'right' }}
                  disabled={!showButtons}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  className="mx-2"
                  style={{ float: 'right' }}
                  disabled={!showButtons}
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              </>
            </div>
          </Cols>
        </Row>
        {/* <SaveMultipleStores toggle={toggle} modal={modal} /> */}
        <Form onSubmit={handleSubmit}>
          <hr />
          <div className="container">
            <Row>
              <Cols xxs="12" className="row icon-cards-row mb-2">
                <Cols xs="12" sm="12" md="4" className="mb-4 mt-3 mr-3">
                  <br />
                  <br />
                  <h5>
                    <b className="mt-4">Profile Details</b>
                  </h5>
                </Cols>
                <Cols className=" mt-3">
                  <>
                    {!firstname && !lastname ? (
                      <div className="d-flex alignItem-cente justify-content-center">
                        <span
                          className=" mt-4"
                          style={{ marginBottom: '20px' }}
                        >
                          <Spinner
                            animation="border"
                            role="status"
                            style={{ width: '40px', height: '40px' }}
                          >
                            <span className="visually-hidden bg-primary">
                              Loading...
                            </span>
                          </Spinner>
                        </span>
                      </div>
                    ) : (
                      <Card>
                        <CardBody className="m-2">
                          <Row>
                            <Cols>
                              <div style={{ display: 'inline' }}>
                                {base64Image && defaultImage ? (
                                  <img
                                    src={base64Image}
                                    alt="demo.png"
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src = 'demo.png';
                                    }}
                                    style={{ height: '40px', width: '40px' }}
                                  ></img>
                                ) : (
                                  <img
                                    src="demo.png"
                                    alt="demo.png"
                                    style={{ height: '40px', width: '40px' }}
                                  ></img>
                                )}
                              </div>
                              <div style={{ display: 'inline' }}>
                                <label
                                  for="profileImg"
                                  className="ml-3 uploadBtn"
                                  outline
                                >
                                  Upload Photo
                                </label>
                                <input
                                  type="file"
                                  id="profileImg"
                                  accept="image/*"
                                  style={{ display: 'none' }}
                                  onChange={(e) => {
                                    handleImageChange(e);
                                    setShowButtons(true);
                                  }}
                                />
                                <Button
                                  type="button"
                                  color="danger"
                                  className="ml-2"
                                  outline
                                  onClick={handleRemoveSelectedImage}
                                  disabled={removeButtonDisable}
                                >
                                  Remove Photo
                                </Button>
                              </div>
                            </Cols>
                          </Row>
                          <hr />

                          {infoMsg !== '' ? InfoMessage(infoMsg) : null}
                          {warningMsg !== ''
                            ? WarningMessage(warningMsg)
                            : null}
                          {errorMsg !== '' ? ErrorMessage(errorMsg) : null}
                          <Row className="mb-2">
                            <Cols>
                              <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                                <Label for="firstname" className="mr-2">
                                  <b>First Name</b>
                                </Label>
                                <Input
                                  id="examplefirstname"
                                  name="firstname"
                                  type="text"
                                  minLength="3"
                                  maxLength="25"
                                  value={firstname}
                                  onChange={(e) => {
                                    setFirstName(e.target.value.trimStart());
                                    setNotificationMsg();
                                    setShowButtons(true);
                                  }}
                                />
                              </FormGroup>
                            </Cols>
                            <Cols>
                              <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                                <Label for="lastname" className="mr-2">
                                  <b>Last Name</b>
                                </Label>
                                <Input
                                  id="examplelastname"
                                  name="lastname"
                                  type="text"
                                  minLength="3"
                                  maxLength="25"
                                  value={lastname}
                                  onChange={(e) => {
                                    setLastName(e.target.value.trimStart());
                                    setNotificationMsg();
                                    setShowButtons(true);
                                  }}
                                />
                              </FormGroup>
                            </Cols>
                          </Row>
                          <p>
                            Use your first and last name as they appear on your
                            government-issued ID.
                          </p>
                          <Row>
                            <Cols>
                              <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                                <Label for="lastname" className="mr-2">
                                  <b>Username</b>
                                </Label>
                                <Input
                                  id="examplelastname"
                                  name="lastname"
                                  type="text"
                                  minLength="3"
                                  maxLength="25"
                                  value={userName}
                                  onChange={(e) => {
                                    setUserName(e.target.value.trimStart());
                                    setNotificationMsg();
                                    setShowButtons(true);
                                  }}
                                />
                              </FormGroup>
                            </Cols>
                            <Cols>
                              <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                                <Label for="lastname" className="mr-2">
                                  <b>Phone (optional)</b>
                                </Label>
                                <Input
                                  id="examplelastname"
                                  name="lastname"
                                  type="text"
                                  minLength="11"
                                  // maxLength='20'
                                  value={phone}
                                  onChange={(e) => {
                                    setPhone(
                                      e.target.value.replace(/[^0-9]/gi, '')
                                    );
                                    setNotificationMsg();
                                    setShowButtons(true);
                                  }}
                                />
                              </FormGroup>
                            </Cols>
                          </Row>
                          <Row>
                            <Cols>
                              <FormGroup className="mb-2 me-sm-2 mb-sm-0 mt-3 ml-1">
                                <Label for="email" className="mr-2">
                                  <b>Email: </b>
                                </Label>
                                <span>
                                  <b>{currentEmail}</b>
                                </span>
                              </FormGroup>
                            </Cols>
                            <Cols>
                              <FormGroup className="mb-2 mt-3 me-sm-2 mb-sm-0">
                                <span
                                  className="changeEmail"
                                  style={{ textDecoration: 'underline' }}
                                  onClick={handlePasswordToggle}
                                >
                                  Change Password
                                </span>
                                <span
                                  className="changeEmail"
                                  style={{
                                    textDecoration: 'underline',
                                    float: 'right',
                                  }}
                                  onClick={handleEmailToggle}
                                >
                                  Change Email
                                </span>
                              </FormGroup>
                            </Cols>
                          </Row>
                        </CardBody>
                      </Card>
                    )}
                  </>
                </Cols>
              </Cols>
            </Row>
            <hr />
            <Row>
              <Cols xxs="12" className="row icon-cards-row mb-2">
                <Cols xs="12" sm="12" md="4" className="mb-4 mt-3 mr-3">
                  <br />
                  <br />
                  <h5>
                    <b className="mt-5">Preferred Language</b>
                  </h5>
                  <p className="mr-5">
                    You will see Shopilam in the language you select here. It
                    shall not effect the language your customers see on your
                    online store.
                  </p>
                </Cols>
                <Cols className="mb-4 mt-3">
                  <>
                    <Card>
                      <CardBody className="m-2">
                        <FormGroup>
                          <div>
                            <Label for="exampleSelect">
                              <b>Language</b>
                            </Label>
                            <Select
                              options={langOptions}
                              placeholder={
                                language ? language : 'Select Language'
                              }
                              defaultValue={language}
                              onChange={handleLanguage}
                            />
                          </div>
                          <div className="mt-2">
                            <Label for="exampleSelect">
                              <b>Country</b>
                            </Label>
                            <Select
                              options={countryOptions}
                              placeholder={country ? country : 'Select Country'}
                              defaultValue={country}
                              onChange={handleCountry}
                            />
                          </div>
                          <div className="mt-2">
                            <Label for="exampleSelect">
                              <b>Region</b>
                            </Label>
                            <Select
                              options={regionOptions}
                              placeholder={region ? region : 'Select Region'}
                              defaultValue={region}
                              onChange={handleRegion}
                            />
                          </div>
                        </FormGroup>
                        {language && country && region && (
                          <div className="mt-4">
                            <b>REGIONAL FORMAT</b>
                            <p>
                              Your language, country and region formats are set
                              for{' '}
                              <b>
                                {language}, {region}, {country}.
                              </b>
                              <br />
                            </p>
                          </div>
                        )}
                      </CardBody>
                    </Card>
                  </>

                  <div></div>
                </Cols>
              </Cols>
            </Row>
            <hr />
            <Row>
              <Cols xxs="12" className="row icon-cards-row mb-2">
                <Cols xs="12" sm="12" md="4" className="mb-4 mt-3 mr-3">
                  <br />
                  <br />
                  <h5>
                    <b className="mt-5">Timezone</b>
                  </h5>
                </Cols>
                <Cols className="mb-4 mt-3">
                  <>
                    <Card>
                      <CardBody className="m-2">
                        <FormGroup>
                          <Label for="exampleSelect">
                            <b className="ml-1">Timezone</b>
                          </Label>
                          <Select
                            options={timezoneOptions}
                            placeholder={
                              timezone ? timezone : 'Select Timezone'
                            }
                            defaultValue={timezone}
                            onChange={handleTimezone}
                          />
                        </FormGroup>
                        <p>
                          This is the timezone for your account.To set the
                          timezone for your Shopilam admin, go to the General
                          section in Settings.
                        </p>
                      </CardBody>
                    </Card>
                  </>

                  <div></div>
                </Cols>
              </Cols>
            </Row>
          </div>
        </Form>

        {/* Change Email Modal */}
        <div>
          <Modal toggle={handleEmailToggle} isOpen={emailModal}>
            <ModalHeader toggle={handleEmailToggle}>
              <b>Change Your Email</b>
            </ModalHeader>
            <ModalBody>
              {modalInfoMsg !== '' ? InfoMessage(modalInfoMsg) : null}
              {modalWarningMsg !== '' ? WarningMessage(modalWarningMsg) : null}
              {modalErrorMsg !== '' ? ErrorMessage(modalErrorMsg) : null}
              <div>
                <label>Current Password</label>
                <Input
                  className="mb-3"
                  type="password"
                  required
                  autoComplete="off"
                  value={oldPassword}
                  onChange={(e) => {
                    setOldPassword(e.target.value);
                    setNotificationMsg();
                  }}
                />
                <label>Email Address</label>
                <Input
                  type="email"
                  required
                  autoComplete="off"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setNotificationMsg();
                  }}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={(e) => {
                  handleUpdateEmail(e);
                }}
                disabled={!oldPassword || !email}
              >
                Change Email
              </Button>
              <Button
                type="button"
                onClick={(e) => {
                  setOldPassword('');
                  setEmail('');
                }}
                disabled={!oldPassword && !email}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>

        {/* Change Password Modal */}
        <div>
          <Modal toggle={handlePasswordToggle} isOpen={passwordModal}>
            <ModalHeader toggle={handlePasswordToggle}>
              <b>Change Your Password</b>
            </ModalHeader>
            <ModalBody>
              {modalInfoMsg !== '' ? InfoMessage(modalInfoMsg) : null}
              {modalWarningMsg !== '' ? WarningMessage(modalWarningMsg) : null}
              {modalErrorMsg !== '' ? ErrorMessage(modalErrorMsg) : null}
              <div>
                <Label>Old Password</Label>
                <Input
                  type="password"
                  required
                  autoComplete="off"
                  value={oldPassword}
                  onChange={(e) => {
                    setOldPassword(e.target.value);
                    setNotificationMsg();
                  }}
                />
                <br />
                <Label>New Password</Label>
                <Input
                  type="password"
                  required
                  autoComplete="off"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setNotificationMsg();
                  }}
                />{' '}
                <br />
                <Label>Confirm Password</Label>
                <Input
                  type="password"
                  required
                  autoComplete="off"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setNotificationMsg();
                  }}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                disabled={!oldPassword || !password || !confirmPassword}
                onClick={(e) => {
                  handleUpdatePassword(e);
                }}
              >
                Change Password
              </Button>
              <Button
                type="button"
                onClick={(e) => {
                  setOldPassword('');
                  setPassword('');
                  setConfirmPassword('');
                }}
                disabled={!oldPassword && !password && !confirmPassword}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </main>
    </>
  );
};

export default AccountSetting;
