import axios from "axios";
import { getCookie } from "../helpers/cookie";
import { getAPIURL } from "./utils";
const url = getAPIURL();

// get all Stores
export const getAllUsers = async ({ limit, page, searchValue, sorting }) => {
  let token = getCookie();
  const params = new URLSearchParams();
  params.append("limit", limit?.toString());
  params.append("page", page?.toString());
  params.append("search", searchValue?.toString().trim());
  params.append("sorting", sorting.toString());

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  };

  const response = await axios.get(`${url}/api/users/getAllUsers`, config);
  return response;
};

// update users data
export const updateUser = async (user) => {
  let token = getCookie();
  console.log("user", user);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(`${url}/api/users/userUpdate`, user, config);
  console.log("response", response);
  return response;
};

// get Store by UserId
export const getUserById = async (id) => {
  const token = getCookie();
  const data = { _id: id };
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(`${url}/api/users/userId`, data, config);
  return response;
};
