import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
} from '../constants/authConstants';
import { signIn } from '../../api/auth';
import { setAuthDataInStorage } from '../../helpers/auth';

export const login = (credentials) => async (dispatch) => {
  dispatch({ type: USER_LOGIN_REQUEST });
  try {
    const { data } = await signIn(credentials);
    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
    setAuthDataInStorage(data.token, data.user);
  } catch (e) {
    dispatch({ type: USER_LOGIN_FAIL, payload: e.errorMessage });
  }
};

export const userInfo = (username) => {
  return {
    type: 'userInfo',
    username: username,
  };
};
export const userImage = (image) => {
  return {
   type: "updateUserImage",
    image: image,
  };
};
