import React, { useState } from 'react';
import { Breadcrumb, Button, Nav, NavItem, NavLink, Row } from 'reactstrap';
import classnames from 'classnames';
import { Cols } from '../SeparatorStyle/SeparatorStyle';
import { Link } from 'react-router-dom';
import CityTypos from './CityTypos';
import CityAnyway from './CityAnyway';
import CitiesList from './CitiesList';

const CitiesTabs = () => {
  const [activeTab, setActiveTab] = useState('Cities');

  return (
    <main>
      <Row>
        <Cols xxs="12">
          <h1>Cities Management</h1>
          <div style={{ display: 'inline' }}>
            <Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
              <ol className="breadcrumb pt-0">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" style={{ color: 'black' }}>
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Cities Management
                </li>
              </ol>
            </Breadcrumb>
          </div>
        </Cols>
      </Row>
      <Nav
        tabs
        className="separator-tabs mb-3"
        style={{ cursor: 'pointer', alignContent: 'center' }}
      >
        <NavItem>
          <NavLink
            className={`${classnames({
              active: activeTab === 'Cities',
              'nav-link': true,
            })} ${activeTab === 'Cities' ? 'text-primary' : ' text-muted'}`}
            onClick={() => {
              setActiveTab('Cities');
            }}
            location={{}}
            to="#"
          >
            Cities
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${classnames({
              active: activeTab === 'Anyway Cities',
              'nav-link': true,
            })} ${activeTab === 'Anyway Cities' ? 'text-primary' : ' text-muted'}`}
            onClick={() => {
              setActiveTab('Anyway Cities');
            }}
            location={{}}
            to="#"
          >
            Anyway Cities
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${classnames({
              active: activeTab === 'Typos Cities',
              'nav-link': true,
            })} ${activeTab === 'Typos Cities' ? 'text-primary' : ' text-muted'}`}
            onClick={() => {
              setActiveTab('Typos Cities');
            }}
            location={{}}
            to="#"
          >
            City Typos
          </NavLink>
        </NavItem>
      </Nav>
      {activeTab === 'Cities' ? (
        <CitiesList />
      ) : activeTab === 'Anyway Cities' ? (
        <CityAnyway />
      ) : (
        <CityTypos />
      )}
    </main>
  );
};

export default CitiesTabs;
