import React, { useState, useCallback, useEffect, useMemo } from "react";
import {
  Card,
  Button,
  Row,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Spinner,
  FormGroup,
  Label,
  Alert,
  UncontrolledPopover,
  PopoverBody,
  CardBody,
} from "reactstrap";
import { toast } from "react-toastify";
import { Cols } from "../SeparatorStyle/SeparatorStyle";
import {
  addAnyWayCityIntoCities,
  deleteAnywayCity,
  getAnywayCities,
  updateAnywayCityName,
} from "../../api/cityAnyway";

import DataTable from "../tables/table";
import Select from "react-select";
import { handleSearch } from "../../helpers/searchHook";

const CityAnyway = () => {
  const sortOptions = [
    { value: "ascending-name", label: "Name (A-Z)" },
    { value: "descending-name", label: "Name (Z-A)" },
  ];
  const [total, setTotal] = useState("");
  const [pageLimit, setPageLimit] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [anywayCities, setAnywayCities] = useState([]);
  const [emptyStatus, setEmptyStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchTrigger, setSearchTrigger] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [sortingFilterValue, setSortingFilterValue] = useState("");
  const [deletingId, setDeletingId] = useState("");
  const [editCity, setEditCity] = useState({ _id: "", anyWayCity: "" });
  const [addToCities, setAddToCities] = useState("");
  const [updateLoader, setUpdateLoader] = useState(false);

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const loadAnywayCities = useCallback(
    async ({
      limit = pageLimit,
      page = pageNumber,
      searchValue = search.trim().length > 2 ? search : "",
      sorting = sortingFilterValue ? sortingFilterValue?.value : "",
    }) => {
      try {
        const { status, data } = await getAnywayCities({
          limit,
          page,
          searchValue,
          sorting,
        });
        setTotal(data.count);

        if (status === 200) {
          setAnywayCities(data.anyWayCities);

          if (data.count === 0 && !search && !sortingFilterValue) {
            setEmptyStatus(true);
          } else {
            setEmptyStatus(false);
          }
        }

        setLoading(false);
        setFilterLoading(false);
        setPaginationLoading(false);
      } catch (err) {
        toast.error("Cities data loading error! ");
      }
    },

    [pageLimit, pageNumber, searchTrigger, sortingFilterValue]
  );

  useEffect(() => {
    loadAnywayCities({});
  }, [loadAnywayCities]);

  const totalPages = Math.ceil(total / pageLimit);

  const handleCreateCities = async () => {
    let format = {
      value: addToCities.trim(),
      label: toTitleCase(addToCities.trim()),
    };

    confirmModale();
    try {
      const response = await addAnyWayCityIntoCities(format);
      if (response.status === 201) {
        toast.success(response.data);
        loadAnywayCities({});
        setFilterLoading(true);
      }
    } catch (error) {
      toast.error(error.response.data || "Error While Adding into City!");
    }
  };

  // const handleSort = async (e) => {
  //   setSortingFilterValue(e);
  //   let sorting = [];

  //   if (!e) {
  //     sorting = anywayCities.sort((a, b) => b._id.localeCompare(a._id));
  //   } else {
  //     if (e?.value.includes("-name")) {
  //       if (e.value === "ascending-name") {
  //         sorting = anywayCities.sort((a, b) =>
  //           a?.anyWayCity
  //             .toLowerCase()
  //             .localeCompare(b?.anyWayCity?.toLowerCase())
  //         );
  //       } else {
  //         sorting = anywayCities.sort((a, b) =>
  //           b?.anyWayCity
  //             ?.toLowerCase()
  //             ?.localeCompare(a?.anyWayCity?.toLowerCase())
  //         );
  //       }
  //     }
  //   }
  //   setAnywayCities([...sorting]);
  // };

  const modalEditToggle = () => {
    setEditCity({ _id: "", anyWayCity: "" });
  };

  const confirmModale = () => {
    setAddToCities("");
  };

  const handleDeleteAnywayCity = async (city_id) => {
    setDeletingId(city_id);
    try {
      const { data, status } = await deleteAnywayCity(city_id);
      if (status === 200) {
        toast.success(data);
        setFilterLoading(true);
        loadAnywayCities({});
      } else {
        toast.error("Error While Delete Anyway City");
      }
      setDeletingId("");
    } catch (error) {
      setDeletingId("");
      toast.error("Error While Delete Anyway City");
    }
  };

  const handleUpdateCity = async () => {
    let editData = {
      cityId: editCity?._id,
      anyWayCity: toTitleCase(editCity?.anyWayCity),
    };
    setUpdateLoader(true);
    try {
      const { data, status } = await updateAnywayCityName(editData);
      if (status === 200) {
        toast.success(data);
        modalEditToggle();
        loadAnywayCities({});
        setFilterLoading(true);
      }
      setUpdateLoader(false);
    } catch (error) {
      setUpdateLoader(false);
      toast.error(error.response.data || "Error While Update Anyway City!");
    }
  };

  const currentPageData = anywayCities?.map((obj, index) => {
    return {
      cityName: (
        <>
          {obj?.anyWayCity?.slice(0, 20)}
          {obj?.anyWayCity?.length > 20 && (
            <>
              {" ... "}
              <i className="simple-icon-info pointer" id={"Title-" + index}>
                <UncontrolledPopover
                  trigger="hover"
                  placement="top"
                  target={"Title-" + index}
                >
                  <PopoverBody className="text-center">
                    {obj?.anyWayCity}
                  </PopoverBody>
                </UncontrolledPopover>
              </i>
            </>
          )}
        </>
      ),

      action:
        deletingId === obj?._id || updateLoader ? (
          <Spinner size={"sm"} color="primary" />
        ) : (
          <>
            <span
              className="simple-icon-plus text-primary pointer"
              style={{ fontSize: "14px" }}
              onClick={() => {
                setAddToCities(obj.anyWayCity);
              }}
            ></span>
            <span
              className="simple-icon-pencil text-primary mx-4 pointer"
              style={{ fontSize: "14px" }}
              onClick={() => {
                setEditCity(obj);
              }}
            ></span>
            <span
              className="simple-icon-trash text-danger pointer"
              style={{ fontSize: "14px" }}
              onClick={() => handleDeleteAnywayCity(obj?._id)}
            ></span>
          </>
        ),
    };
  });

  const cols = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "cityName",
        cellClass: "w-15",
        Cell: (props) => <>{props.value}</>,
      },

      {
        Header: "Actions",
        accessor: "action",
        cellClass: "w-15",
        Cell: (props) => <>{props.value}</>,
      },
    ],
    [anywayCities, filterLoading, paginationLoading, searchTrigger]
  );

  return (
    <>
      <Row>
        <Cols xxs="12">
          <div>
            {loading ? (
              <div
                className=" position-relative"
                style={{
                  height: "50vh",
                }}
              >
                <span className=" position-absolute loading"></span>
              </div>
            ) : emptyStatus ? (
              <Card>
                <div
                  className=" d-flex justify-content-center align-items-center text-danger"
                  style={{
                    height: "200px",
                  }}
                >
                  <h4>
                    <b>No Anyway Cities Found!</b>
                  </h4>
                </div>
              </Card>
            ) : (
              <Card>
                <CardBody>
                  <Row className="d-flex justify-content-center">
                    <Cols lg="6">
                      <div className=" mt-2 d-flex justify-content-between flex-wrap">
                        <div className="mr-2 mb-2 position-relative">
                          <Input
                            disabled={filterLoading || paginationLoading}
                            type="text"
                            name="keyword"
                            value={search}
                            id="search"
                            placeholder="Search"
                            onChange={(e) => {
                              handleSearch({
                                e,
                                setPageNumber,
                                setPageLimit,
                                setFilterLoading,
                                setSearch,
                                setSearchTrigger,
                                searchTrigger,
                              });
                            }}
                            style={{ minWidth: "200px" }}
                            className="rounded-lg"
                          />
                          {search.trim() && search.trim().length < 3 && (
                            <i
                              className="simple-icon-info pointer position-absolute text-danger fw-bold"
                              style={{ right: "-20px", bottom: "12px" }}
                              id={"SearchInfo"}
                            >
                              {" "}
                              <UncontrolledPopover
                                trigger="hover"
                                placement="top"
                                target={"SearchInfo"}
                              >
                                <PopoverBody className="text-center">
                                  <i>Minimum 3 words required!</i>
                                </PopoverBody>
                              </UncontrolledPopover>
                            </i>
                          )}
                          {search &&
                            (filterLoading && search.trim().length > 2 ? (
                              <Spinner
                                color="primary"
                                size={"sm"}
                                style={{ right: "10px", bottom: "12px" }}
                                className=" position-absolute"
                                type="grow"
                              ></Spinner>
                            ) : (
                              <span
                                className="far fa-close fs-6 position-absolute text-danger"
                                style={{
                                  right: "10px",
                                  bottom: "12px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  if (search) {
                                    setSearch("");

                                    setSearchTrigger(!searchTrigger);
                                    setFilterLoading(true);
                                  }
                                  pageNumber > 1 && setPageNumber(1);
                                  pageLimit > 50 && setPageLimit(50);
                                }}
                              ></span>
                            ))}
                        </div>

                        <div className="d-flex">
                          <div style={{ minWidth: "153px" }} className="mr-3">
                            <Select
                              hideSelectedOptions
                              isDisabled={filterLoading || paginationLoading}
                              isClearable
                              value={sortingFilterValue}
                              onChange={(e) => {
                                setPaginationLoading(true);
                                if (e) {
                                  setSortingFilterValue(e);
                                  // handleSort(e);
                                } else {
                                  setSortingFilterValue("");
                                  // handleSort("");
                                }
                              }}
                              options={sortOptions}
                              placeholder="Sort Filters"
                            />
                          </div>
                        </div>
                      </div>
                      {paginationLoading && (
                        <div className="mt-3 mx-3">
                          <Alert color="info">
                            <Spinner
                              color="light"
                              size={"sm"}
                              style={{ marginBottom: "3px" }}
                            ></Spinner>{" "}
                            &nbsp;
                            <span style={{ fontSize: "16px", color: "black" }}>
                              Anyway Cities Loading!
                            </span>
                          </Alert>
                        </div>
                      )}

                      {filterLoading ? (
                        <div
                          className=" position-relative"
                          style={{
                            height: "200px",
                          }}
                        >
                          <span className=" position-absolute loading"></span>
                        </div>
                      ) : anywayCities?.length === 0 ? (
                        <div
                          className=" d-flex justify-content-center align-items-center text-danger"
                          style={{
                            height: "200px",
                          }}
                        >
                          <h3>
                            <b>No Anyway Cities Matched!</b>
                          </h3>
                        </div>
                      ) : (
                        <DataTable
                          setPageLimitInParent={setPageLimit}
                          setPageNumberInParent={setPageNumber}
                          fetchData={loadAnywayCities}
                          columns={cols || []}
                          data={currentPageData || []}
                          pageCount={totalPages}
                          setPaginationLoading={setPaginationLoading}
                          paginationLoading={paginationLoading}
                          selectedLength={0}
                        />
                      )}
                    </Cols>
                  </Row>
                </CardBody>
              </Card>
            )}
          </div>
          <Modal isOpen={editCity?._id}>
            <ModalHeader toggle={modalEditToggle}>
              <h2 className="m-0 p-0">
                <b>Update AnyWay City</b>
              </h2>
            </ModalHeader>
            <ModalBody>
              <div>
                <FormGroup>
                  <Label for="exampleEmail">City Name</Label>
                  <Input
                    disabled={updateLoader}
                    className="rounded-lg"
                    name="city"
                    value={editCity?.anyWayCity}
                    placeholder="Enter Name"
                    type="text"
                    onChange={(e) => {
                      setEditCity({
                        ...editCity,
                        anyWayCity: e.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </div>
            </ModalBody>
            <ModalFooter className="justify-content-center">
              <Button
                color="danger"
                onClick={() => {
                  modalEditToggle();
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={
                  !editCity?.anyWayCity.trim() ||
                  anywayCities.some(
                    (obj) =>
                      obj._id === editCity?._id &&
                      obj.anyWayCity === editCity?.anyWayCity.trim()
                  ) ||
                  updateLoader
                }
                color="success"
                onClick={() => {
                  if (editCity.anyWayCity.trim()) {
                    handleUpdateCity();
                  }
                }}
              >
                Update
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={addToCities}>
            <ModalHeader toggle={confirmModale}>Confirm</ModalHeader>
            <ModalBody>
              <h4>
                Are you really want to add <b>{addToCities}</b> To Cities?
              </h4>
            </ModalBody>
            <ModalFooter className="justify-content-center">
              <Button color="primary" onClick={handleCreateCities}>
                Yes
              </Button>{" "}
              <Button color="secondary" onClick={confirmModale}>
                No
              </Button>
            </ModalFooter>
          </Modal>
        </Cols>
      </Row>
    </>
  );
};

export default CityAnyway;
