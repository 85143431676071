export const ALL_ORDERS_REQUEST = "ALL_ORDERS_REQUEST";
export const ALL_ORDERS_SUCCESS = "ALL_ORDERS_SUCCESS";
export const ALL_ORDERS_FAIL = "ALL_ORDERS_FAIL";

export const REFUNDED_ORDERS_REQUEST = "REFUNDED_ORDERS_REQUEST";
export const REFUNDED_ORDERS_SUCCESS = "REFUNDED_ORDERS_SUCCESS";
export const REFUNDED_ORDERS_FAIL = "REFUNDED_ORDERS_FAIL";

export const FULFILLED_ORDERS_REQUEST = "FULFILLED_ORDERS_REQUEST";
export const FULFILLED_ORDERS_SUCCESS = "FULFILLED_ORDERS_SUCCESS";
export const FULFILLED_ORDERS_FAIL = "FULFILLED_ORDERS_FAIL";

export const PENDING_ORDERS_REQUEST = "PENDING_ORDERS_REQUEST";
export const PENDING_ORDERS_SUCCESS = "PENDING_ORDERS_SUCCESS";
export const PENDING_ORDERS_FAIL = "PENDING_ORDERS_FAIL";

export const UNFULFILLED_ORDERS_REQUEST = "UNFULFILLED_ORDERS_REQUEST";
export const UNFULFILLED_ORDERS_SUCCESS = "UNFULFILLED_ORDERS_SUCCESS";
export const UNFULFILLED_ORDERS_FAIL = "UNFULFILLED_ORDERS_FAIL";

export const OPEN_ORDERS_REQUEST = "OPEN_ORDERS_REQUEST";
export const OPEN_ORDERS_SUCCESS = "OPEN_ORDERS_SUCCESS";
export const OPEN_ORDERS_FAIL = "OPEN_ORDERS_FAIL";

export const CLOSED_ORDERS_REQUEST = "CLOSED_ORDERS_REQUEST";
export const CLOSED_ORDERS_SUCCESS = "CLOSED_ORDERS_SUCCESS";
export const CLOSED_ORDERS_FAIL = "CLOSED_ORDERS_FAIL";

export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL";
export const ORDER_DETAILS_UNFULFILLED_ITEMS =
  "ORDER_DETAILS_UNFULFILLED_ITEMS";
export const ORDER_DETAILS_FULFILLED_ITEMS = "ORDER_DETAILS_FULFILLED_ITEMS";
