import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from "react";
import {
  Card,
  CardBody,
  Row,
  Spinner,
  Breadcrumb,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  FormGroup,
  Form,
  UncontrolledPopover,
  PopoverBody,
  Alert,
} from "reactstrap";
import Swal from "sweetalert2";
import { getAllUsers, updateUser } from "../../api/users";
import { getAccountByUserId } from "../../api/accounts";
import { toast } from "react-toastify";
import { accountDeletion, adminPassword } from "../../api/auth";
import { Cols } from "../SeparatorStyle/SeparatorStyle";
import DataTable from "../tables/table";
import Select from "react-select";
import { Link } from "react-router-dom";
import { handleSearch } from "../../helpers/searchHook";

const RegisteredUser = () => {
  const sortOptions = [
    { value: "ascending-firstName", label: "First Name (A-Z)" },
    { value: "descending-firstName", label: "First Name (Z-A)" },
    { value: "ascending-lastName", label: "Last Name (A-Z)" },
    { value: "descending-lastName", label: "Last Name (Z-A)" },
    { value: "ascending-userName", label: "User Name (A-Z)" },
    { value: "descending-userName", label: "User Name (Z-A)" },
    { value: "ascending-email", label: "Email (A-Z)" },
    { value: "descending-email", label: "Email (Z-A)" },
  ];

  const [total, setTotal] = useState("");
  const [pageLimit, setPageLimit] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [emptyStatus, setEmptyStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchTrigger, setSearchTrigger] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [sortingFilterValue, setSortingFilterValue] = useState("");

  // const delRef = useRef(null);
  const [allUsers, setAllUsers] = useState([]);

  const [open, setOpen] = useState(false);
  // const [delUser, setDelUser] = useState(false);
  // const [delUserEmail, setDelUserEmail] = useState();
  // const [userId, setUserID] = useState();
  const [editRegisteredUser, setEditRegisteredUser] = useState({
    firstname: "",
    lastname: "",
    email: "",
    username: "",
    phone: "",
  });

  // const user = useCallback(async () => {
  //   setIsLoading(true);
  //   try {
  //     const userData = await getAllUsers();
  //     setAllUsers(userData.data);
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.log('Error found when fetching Cities data!');
  //     setIsLoading(true);
  //   }
  // }, [setAllUsers]);

  // useEffect(() => {
  //   user();
  // }, [user]);

  const loadAllUsers = useCallback(
    async ({
      limit = pageLimit,
      page = pageNumber,
      searchValue = search.trim().length > 2 ? search : "",
      sorting = sortingFilterValue ? sortingFilterValue?.value : "",
    }) => {
      try {
        const { status, data } = await getAllUsers({
          limit,
          page,
          searchValue,
          sorting,
        });
        setTotal(data.count);
        setAllUsers(data.usersData);
        if (status === 200) {
          if (data.count === 0 && !search && !sortingFilterValue) {
            setEmptyStatus(true);
          } else {
            setEmptyStatus(false);
          }
        }

        setLoading(false);
        setFilterLoading(false);
        setPaginationLoading(false);
      } catch (err) {
        toast.error("Cities data loading error! ");
      }
    },
    [pageLimit, pageNumber, searchTrigger, sortingFilterValue]
  );

  const totalPages = Math.ceil(total / pageLimit);

  useEffect(() => {
    loadAllUsers({});
  }, [loadAllUsers]);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setEditRegisteredUser({ ...editRegisteredUser, [name]: value });
  };

  const dataShow = (user) => {
    setEditRegisteredUser({
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      username: user.username,
      phone: user.phone,
      _id: user._id,
    });
  };

  // const deleteUserHandler = async (id) => {
  //   const { data } = await getAccountByUserId(id);
  //   data.map((user) => setUserID(user._id));
  // };

  // const handleDeleteUser = async () => {
  //   try {
  //     let pswrd = delRef.current.value;
  //     const { data } = await accountDeletion(pswrd, userId);
  //     toast.success(data.successMessage);
  //     setDelUser(false);
  //     user();
  //   } catch (error) {
  //     setDelUser(true);
  //     if (error.response) {
  //       toast.error(error.response?.data.errorMessage);
  //     }
  //   }
  // };

  const handleSubmit = async () => {
    try {
      await updateUser(editRegisteredUser);
      loadAllUsers({});
    } catch (error) {
      console.log(error.message);
    }
  };

  const showDeleteAlert = async (userEmail, userId) => {
    const { data } = await getAccountByUserId(userId);
    const Id = data.map((user) => user._id);
    let userData = Id.toString();
    Swal.fire({
      text: "Are you really want to delete!",
      html: `Do you really want to delete <strong style="color: blue;">${userEmail}</strong> ? <br/><br/>
      <strong style="color: red;">All your data would be erased permanently!</strong>
      `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          await Swal.fire({
            title: "Enter your password",
            input: "password",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Delete",
            inputPlaceholder: "Enter your password",
            buttons: false,
            preConfirm: async (password) => {
              try {
                const data = await adminPassword(password);
                Swal.fire(data?.data.successMessage, "", "success");
              } catch (error) {
                Swal.fire(error.response?.data.errorMessage, "", "error");
                return Promise.reject();
              }
            },
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                title: "Enter user password",
                input: "password",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Delete",
                inputPlaceholder: "Enter your user password",
                buttons: false,
                preConfirm: async (password) => {
                  try {
                    const data = await accountDeletion(
                      password,
                      userData,
                      userId
                    );
                    Swal.fire(data?.data.successMessage, "", "success");
                    loadAllUsers({});
                  } catch (error) {
                    Swal.fire(error.response?.data.errorMessage, "", "error");
                  }
                },
              });
            } else {
              Swal.fire("Cancelled", "Your account is safe!", "error");
            }
          });
        })();
      } else {
        Swal.fire("Cancelled", "Your account is safe!", "error");
      }
    });
  };

  // const handleSort = async (e) => {
  //   setSortingFilterValue(e);
  //   let sorting = [];
  //   if (!e) {
  //     sorting = allUsers.sort((a, b) => b._id.localeCompare(a._id));
  //   } else {
  //     if (e?.value.includes("-firstName")) {
  //       if (e.value === "ascending-firstName") {
  //         sorting = allUsers.sort((a, b) =>
  //           a?.firstname
  //             .toLowerCase()
  //             .localeCompare(b?.firstname?.toLowerCase())
  //         );
  //       } else {
  //         sorting = allUsers.sort((a, b) =>
  //           b?.firstname
  //             ?.toLowerCase()
  //             ?.localeCompare(a?.firstname?.toLowerCase())
  //         );
  //       }
  //     } else if (e?.value.includes("-lastName")) {
  //       if (e.value === "ascending-lastName") {
  //         sorting = allUsers.sort((a, b) =>
  //           a?.lastname.toLowerCase().localeCompare(b?.lastname?.toLowerCase())
  //         );
  //       } else {
  //         sorting = allUsers.sort((a, b) =>
  //           b?.lastname.toLowerCase().localeCompare(a?.lastname?.toLowerCase())
  //         );
  //       }
  //     } else if (e?.value.includes("-userName")) {
  //       if (e.value === "ascending-userName") {
  //         sorting = allUsers.sort((a, b) =>
  //           a?.username.toLowerCase().localeCompare(b?.username?.toLowerCase())
  //         );
  //       } else {
  //         sorting = allUsers.sort((a, b) =>
  //           b?.username.toLowerCase().localeCompare(a?.username?.toLowerCase())
  //         );
  //       }
  //     } else if (e?.value.includes("-email")) {
  //       if (e.value === "ascending-email") {
  //         sorting = allUsers.sort((a, b) =>
  //           a?.email.toLowerCase().localeCompare(b?.email?.toLowerCase())
  //         );
  //       } else {
  //         sorting = allUsers.sort((a, b) =>
  //           b?.email.toLowerCase().localeCompare(a?.email?.toLowerCase())
  //         );
  //       }
  //     }
  //   }
  //   setAllUsers([...sorting]);
  // };

  const currentPageData = allUsers?.map((obj, index) => {
    return {
      firstName:
        (
          <>
            {obj?.firstname.slice(0, 20)}
            {obj?.firstname?.trim().length > 20 && (
              <>
                {" ... "}
                <i
                  className="simple-icon-info pointer"
                  id={"firstName-" + index}
                >
                  <UncontrolledPopover
                    trigger="hover"
                    placement="top"
                    target={"firstName-" + index}
                  >
                    <PopoverBody className="text-center">
                      {obj?.firstname}
                    </PopoverBody>
                  </UncontrolledPopover>
                </i>
              </>
            )}
          </>
        ) || "N/A",
      lastName:
        (
          <>
            {obj?.lastname.slice(0, 20)}
            {obj?.lastname?.trim().length > 20 && (
              <>
                {" ... "}
                <i
                  className="simple-icon-info pointer"
                  id={"lastName-" + index}
                >
                  <UncontrolledPopover
                    trigger="hover"
                    placement="top"
                    target={"lastName-" + index}
                  >
                    <PopoverBody className="text-center">
                      {obj?.lastname}
                    </PopoverBody>
                  </UncontrolledPopover>
                </i>
              </>
            )}
          </>
        ) || "N/A",
      email: obj.email || "N/A",
      userName:
        (
          <>
            {obj?.username.slice(0, 20)}
            {obj?.username?.trim().length > 20 && (
              <>
                {" ... "}
                <i
                  className="simple-icon-info pointer"
                  id={"userName-" + index}
                >
                  <UncontrolledPopover
                    trigger="hover"
                    placement="top"
                    target={"userName-" + index}
                  >
                    <PopoverBody className="text-center">
                      {obj?.username}
                    </PopoverBody>
                  </UncontrolledPopover>
                </i>
              </>
            )}
          </>
        ) || "N/A",
      phone: obj.phone || "N/A",
      action: (
        <>
          <span
            className="simple-icon-pencil text-primary pointer mr-3"
            onClick={() => {
              setOpen(true);
              dataShow(obj);
            }}
          ></span>
          <span
            className="simple-icon-trash text-danger pointer"
            onClick={() => {
              showDeleteAlert(obj.email, obj._id);
              // deleteUserHandler(user._id);
              // setDelUser(true);
              // setDelUserEmail(user.email);
            }}
          ></span>
        </>
      ),
    };
  });

  const cols = useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "firstName",
        cellClass: "w-15",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Last Name",
        accessor: "lastName",
        cellClass: "w-15",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Email",
        accessor: "email",
        cellClass: "w-15",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Username",
        accessor: "userName",
        cellClass: "w-15",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Phone",
        accessor: "phone",
        cellClass: "w-15",
        Cell: (props) => <>{props.value}</>,
      },

      {
        Header: "Actions",
        accessor: "action",
        cellClass: "w-15",
        Cell: (props) => <>{props.value}</>,
      },
    ],
    [allUsers, filterLoading, paginationLoading, searchTrigger]
  );

  return (
    <main>
      <Row>
        <Cols xxs="12">
          <h1>Registered User</h1>
          <div style={{ display: "inline" }}>
            <Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
              <ol className="breadcrumb pt-0">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" style={{ color: "black" }}>
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Registered User
                </li>
              </ol>
            </Breadcrumb>
          </div>
        </Cols>
      </Row>
      {/* <Card>
        <CardBody>
          <Row>
            <Cols>
              <table className="mt-3 table table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Firstname</th>
                      <th>Lastname</th>
                      <th>Email</th>
                      <th>Username</th>
                      <th>Phone</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allUsers &&
                      allUsers?.map((user, index) => (
                        <tr key={index} className="list-unstyled">
                          <td>{index + 1}</td>
                          <td>{user.firstname}</td>
                          <td>{user.lastname}</td>
                          <td>{user.email}</td>
                          <td>{user.username}</td>
                          <td>{user.phone ? user.phone : 'N/A'}</td>
                          <td>
                            <span
                              style={{ cursor: 'pointer', color: ' #9c3f94' }}
                              className="simple-icon-pencil fs-5"
                              onClick={() => {
                                setOpen(true);
                                dataShow(user);
                              }}
                            ></span>
                            <span
                              className="cursor-pointer simple-icon-trash danger ml-2 fs-5"
                              style={{ cursor: 'pointer', color: 'red' }}
                              onClick={() => {
                                showDeleteAlert(user.email, user._id);
                                // deleteUserHandler(user._id);
                                // setDelUser(true);
                                // setDelUserEmail(user.email);
                              }}
                            ></span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
            </Cols>
          </Row>
        </CardBody>
      </Card> */}
      <Row>
        <Cols xxs="12">
          <div>
            {loading ? (
              <div
                className=" position-relative"
                style={{
                  height: "50vh",
                }}
              >
                <span className=" position-absolute loading"></span>
              </div>
            ) : emptyStatus ? (
              <Card>
                <div
                  className=" d-flex justify-content-center align-items-center text-danger font-weight-bold"
                  style={{
                    height: "200px",
                    fontSize: "20px",
                  }}
                >
                  <span>No Cities Found!</span>
                </div>
              </Card>
            ) : (
              <Card>
                <CardBody>
                  <div className=" mt-2 d-flex justify-content-between flex-wrap">
                    <div className="mr-2 mb-2 position-relative">
                      <Input
                        disabled={filterLoading || paginationLoading}
                        type="text"
                        name="keyword"
                        value={search}
                        id="search"
                        placeholder="Search"
                        onChange={(e) => {
                          handleSearch({
                            e,
                            setPageNumber,
                            setPageLimit,
                            setFilterLoading,
                            setSearch,
                            setSearchTrigger,
                            searchTrigger,
                          });
                        }}
                        style={{ minWidth: "200px" }}
                        className="rounded-lg"
                      />
                      {search.trim() && search.trim().length < 3 && (
                        <i
                          className="simple-icon-info pointer position-absolute text-danger fw-bold"
                          style={{ right: "-20px", bottom: "12px" }}
                          id={"SearchInfo"}
                        >
                          {" "}
                          <UncontrolledPopover
                            trigger="hover"
                            placement="top"
                            target={"SearchInfo"}
                          >
                            <PopoverBody className="text-center">
                              <i>Minimum 3 words required!</i>
                            </PopoverBody>
                          </UncontrolledPopover>
                        </i>
                      )}
                      {search &&
                        (filterLoading && search.trim().length > 2 ? (
                          <Spinner
                            color="primary"
                            size={"sm"}
                            style={{ right: "10px", bottom: "12px" }}
                            className=" position-absolute"
                            type="grow"
                          ></Spinner>
                        ) : (
                          <span
                            className="far fa-close fs-6 position-absolute text-danger"
                            style={{
                              right: "10px",
                              bottom: "12px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (search) {
                                setSearch("");

                                setSearchTrigger(!searchTrigger);
                                setFilterLoading(true);
                              }
                              pageNumber > 1 && setPageNumber(1);
                              pageLimit > 50 && setPageLimit(50);
                            }}
                          ></span>
                        ))}
                    </div>

                    <div className="d-flex">
                      <div style={{ minWidth: "153px" }}>
                        <Select
                          hideSelectedOptions
                          isDisabled={filterLoading || paginationLoading}
                          isClearable
                          value={sortingFilterValue}
                          onChange={(e) => {
                            setPaginationLoading(true);
                            if (e) {
                              setSortingFilterValue(e);
                              // handleSort(e);
                            } else {
                              setSortingFilterValue("");
                              // handleSort("");
                            }
                          }}
                          options={sortOptions}
                          placeholder="Sort Filters"
                        />
                      </div>
                    </div>
                  </div>
                  {paginationLoading && (
                    <div className="mt-3 mx-3">
                      <Alert color="info">
                        <Spinner
                          color="light"
                          size={"sm"}
                          style={{ marginBottom: "3px" }}
                        ></Spinner>{" "}
                        &nbsp;
                        <span style={{ fontSize: "16px", color: "black" }}>
                          Users Loading!
                        </span>
                      </Alert>
                    </div>
                  )}

                  {filterLoading ? (
                    <div
                      className=" position-relative"
                      style={{
                        height: "200px",
                      }}
                    >
                      <span className=" position-absolute loading"></span>
                    </div>
                  ) : allUsers?.length === 0 ? (
                    <div
                      className=" d-flex justify-content-center align-items-center text-danger"
                      style={{
                        height: "200px",
                      }}
                    >
                      <h3>
                        <b>No Users Matched!</b>
                      </h3>
                    </div>
                  ) : (
                    <DataTable
                      setPageLimitInParent={setPageLimit}
                      setPageNumberInParent={setPageNumber}
                      fetchData={loadAllUsers}
                      columns={cols || []}
                      data={currentPageData || []}
                      pageCount={totalPages}
                      setPaginationLoading={setPaginationLoading}
                      paginationLoading={paginationLoading}
                      selectedLength={0}
                    />
                  )}
                </CardBody>
              </Card>
            )}
          </div>
        </Cols>
      </Row>

      {/* //////////// Update User Modal ////////////////////////// */}

      <Modal isOpen={open} toggle={() => setOpen(false)}>
        <ModalHeader toggle={() => setOpen(false)}>
          <b>Edit User</b>
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup className="my-2 me-sm-2 mb-sm-0">
              <Label for="firstname" className="mr-2">
                <b>First Name</b>
              </Label>
              <Input
                name="firstname"
                type="text"
                value={editRegisteredUser.firstname}
                onChange={changeHandler}
              />
            </FormGroup>
            <FormGroup className="my-2 me-sm-2 mb-sm-0">
              <Label for="lastname" className="mr-2">
                <b>Last Name</b>
              </Label>

              <Input
                name="lastname"
                type="text"
                value={editRegisteredUser.lastname}
                onChange={changeHandler}
              />
            </FormGroup>
            <FormGroup className="my-2 me-sm-2 mb-sm-0">
              <Label for="email" className="mr-2">
                <b>Email</b>
              </Label>
              <Input
                name="email"
                type="text"
                value={editRegisteredUser.email}
                onChange={changeHandler}
              />
            </FormGroup>
            <FormGroup className="my-2 me-sm-2 mb-sm-0">
              <Label for="username" className="mr-2">
                <b>User Name</b>
              </Label>
              <Input
                name="username"
                type="text"
                value={editRegisteredUser.username}
                onChange={changeHandler}
              />
            </FormGroup>
            <FormGroup className="my-2 me-sm-2 mb-sm-0">
              <Label for="phone" className="mr-2">
                <b>Phone</b>
              </Label>
              <Input
                min={0}
                name="phone"
                type="number"
                value={editRegisteredUser.phone}
                onChange={changeHandler}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="primary"
            outline
            onClick={() => {
              handleSubmit();
              setOpen(false);
            }}
          >
            Save
          </Button>
          <Button
            color="secondary"
            className="rounded"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </main>
  );
};

export default RegisteredUser;
