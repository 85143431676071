import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Card, CardBody, Breadcrumb } from 'reactstrap';
import { Cols } from '../../components/SeparatorStyle/SeparatorStyle';

const MyCouriers = () => {
  return (
    <main>
      <Row>
        <Cols xxs="12">
          <h1>Courier Managment</h1>
          <Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
            <ol className="breadcrumb pt-0">
              <li className="breadcrumb-item">
                <Link to="/admin/myCouriers" style={{ color: 'black' }}>
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Courier Managment
              </li>
            </ol>
          </Breadcrumb>
        </Cols>
      </Row>
      {
        <Row>
          <Cols xxs="12" className="row icon-cards-row mb-2 mt-3">
            <Cols xxs="6" sm="4" md="4" className="mb-4">
              <Card>
                <CardBody className="text-center">
                  <i className="iconsminds-shopping-bag ml-2 " />
                  <i
                    className="iconsminds-arrow-right mt-3 "
                    style={{ float: 'right' }}
                  ></i>
                  <p className="card-text font-weight-semibold mb-0">
                    Change Courier
                  </p>
                </CardBody>
              </Card>
            </Cols>
            <Cols xxs="6" sm="4" md="4" className="mb-4">
              <Card>
                <CardBody className="text-center">
                  <i className="iconsminds-chrysler-building ml-2 " />
                  <i
                    className="iconsminds-arrow-right mt-3 "
                    style={{ float: 'right' }}
                  ></i>
                  <p className="card-text font-weight-semibold mb-0">
                    Change City
                  </p>
                </CardBody>
              </Card>
            </Cols>
            <Cols xxs="6" sm="4" md="4" className="mb-4">
              <Card>
                <CardBody className="text-center ">
                  <i className="simple-icon-key ml-2" />
                  <i
                    className="iconsminds-arrow-right mt-3"
                    style={{ float: 'right' }}
                  ></i>
                  <p className="card-text font-weight-semibold mb-0">
                    Change Keys
                  </p>
                </CardBody>
              </Card>
            </Cols>
          </Cols>
        </Row>
        // : <div className="loading" />
      }
    </main>
  );
};

export default MyCouriers;
