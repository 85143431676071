export const currentPage = (state = 1, action) => {
  switch (action.type) {
    case "changePage":
      return (state = action.selectedPage + 1);
    default:
      return state;
  }
};
export const pageSize = (state = 10, action) => {
  switch (action.type) {
    case "pageSize":
      return (state = action.size);
    default:
      return state;
  }
};
