import React, { useState, useCallback, useEffect, useMemo } from "react";
import {
  Table,
  Card,
  Button,
  Row,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Spinner,
  FormGroup,
  Label,
  Alert,
  Badge,
  UncontrolledPopover,
  PopoverBody,
  CardBody,
} from "reactstrap";
import { toast } from "react-toastify";
import { Cols } from "../SeparatorStyle/SeparatorStyle";
import DataTable from "../tables/table";
import {
  createCities,
  deleteCity,
  getAllCities,
  updateCityName,
} from "../../api/cities";
import Select from "react-select";
import { handleSearch } from "../../helpers/searchHook";

const CitiesList = () => {
  const sortOptions = [
    { value: "ascending-name", label: "Name (A-Z)" },
    { value: "descending-name", label: "Name (Z-A)" },
  ];

  const [total, setTotal] = useState("");
  const [pageLimit, setPageLimit] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [allCities, setAllCities] = useState([]);
  const [emptyStatus, setEmptyStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchTrigger, setSearchTrigger] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [sortingFilterValue, setSortingFilterValue] = useState("");
  const [deletingId, setDeletingId] = useState("");

  const [city, setCity] = useState("");
  const [createdCities, setCreatedCities] = useState([]);
  const [editCity, setEditCity] = useState({ value: "", label: "" });
  const [modal, setModal] = useState(false);
  const [createCitiesLoading, setCreateCitiesLoading] = useState(false);

  const loadAllCities = useCallback(
    async ({
      limit = pageLimit,
      page = pageNumber,
      searchValue = search.trim().length > 2 ? search : "",
      sorting = sortingFilterValue ? sortingFilterValue?.value : "",
    }) => {
      try {
        const { status, data } = await getAllCities({
          limit,
          page,
          searchValue,
          sorting,
        });
        setTotal(data.count);

        if (status === 200) {
          setAllCities(data.cities);

          if (data.count === 0 && !search && !sortingFilterValue) {
            setEmptyStatus(true);
          } else {
            setEmptyStatus(false);
          }
        }

        setLoading(false);
        setFilterLoading(false);
        setPaginationLoading(false);
      } catch (err) {
        toast.error("Cities data loading error! ");
      }
    },
    [pageLimit, pageNumber, searchTrigger, sortingFilterValue]
  );

  useEffect(() => {
    loadAllCities({});
  }, [loadAllCities]);

  const totalPages = Math.ceil(total / pageLimit);

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const modalToggle = () => {
    setCity("");
    setCreatedCities([]);
    setModal(!modal);
  };

  const modalEditToggle = () => {
    setEditCity({ value: "", label: "" });
  };

  const handleAddCity = () => {
    if (city.trim()) {
      if (!createdCities.some((v) => v.trim() === city.trim())) {
        setCreatedCities([...createdCities, city]);
      }
    }
    setCity("");
  };

  const handleCreateCities = async () => {
    // e.preventDefault();
    let format = createdCities.map((v) => {
      return { value: v.toLowerCase(), label: toTitleCase(v) };
    });
    setCreateCitiesLoading(true);
    try {
      const response = await createCities(format);
      if (response.status === 201) {
        toast.success(response.data);
        modalToggle();
        loadAllCities({});
        setFilterLoading(true);
      } else {
        toast.warning(response.data);
      }
      setCreateCitiesLoading(false);
    } catch (err) {
      setCreateCitiesLoading(false);
      toast.error("Error While City Addition");
    }
  };

  const handleUpdateCity = async () => {
    let editData = {
      cityId: editCity?._id,
      value: editCity.label?.toLowerCase(),
      label: toTitleCase(editCity?.label),
    };
    setCreateCitiesLoading(true);

    try {
      const { data, status } = await updateCityName(editData);
      if (status === 200) {
        toast.success(data);
        modalEditToggle();
        loadAllCities({});
        setFilterLoading(true);
      } else {
        toast.error(data);
      }
      setCreateCitiesLoading(false);
    } catch (error) {
      setCreateCitiesLoading(false);
      toast.error(error.response.data || "Error While Update City!");
    }
  };

  const handleDeleteCity = async (city_id) => {
    setDeletingId(city_id);
    try {
      const { data, status } = await deleteCity(city_id);
      if (status === 200) {
        toast.success(data);
        setFilterLoading(true);
        loadAllCities({});
      } else {
        toast.error("Error While Delete City");
      }
      setDeletingId("");
    } catch (error) {
      setDeletingId("");
      toast.error("Error While Delete City");
      console.log(error.message);
    }
  };

  // const handleSort = async (e) => {
  //   setSortingFilterValue(e);
  //   let sorting = [];

  //   if (!e) {
  //     sorting = allCities.sort((a, b) => b._id.localeCompare(a._id));
  //   } else {
  //     if (e?.value.includes("-name")) {
  //       if (e.value === "ascending-name") {
  //         sorting = allCities.sort((a, b) =>
  //           a?.label.toLowerCase().localeCompare(b?.label?.toLowerCase())
  //         );
  //       } else {
  //         sorting = allCities.sort((a, b) =>
  //           b?.label?.toLowerCase()?.localeCompare(a?.label?.toLowerCase())
  //         );
  //       }
  //     }
  //   }
  //   setAllCities([...sorting]);
  // };

  const currentPageData = allCities?.map((obj, index) => {
    return {
      cityName: (
        <>
          {obj?.label.slice(0, 20)}
          {obj?.label.length > 20 && (
            <>
              {" ... "}
              <i className="simple-icon-info pointer" id={"Title-" + index}>
                <UncontrolledPopover
                  trigger="hover"
                  placement="top"
                  target={"Title-" + index}
                >
                  <PopoverBody className="text-center">
                    {obj?.label}
                  </PopoverBody>
                </UncontrolledPopover>
              </i>
            </>
          )}
        </>
      ),

      action:
        deletingId === obj?._id ? (
          <Spinner size={"sm"} color="primary" />
        ) : (
          <>
            <span
              className="simple-icon-pencil text-primary mr-4"
              style={{ cursor: "pointer", fontSize: "14px" }}
              onClick={() => {
                setEditCity(obj);
              }}
            ></span>
            <span
              className="simple-icon-trash text-danger"
              style={{ cursor: "pointer", fontSize: "14px" }}
              onClick={() => handleDeleteCity(obj?._id)}
            ></span>
          </>
        ),
    };
  });

  const cols = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "cityName",
        cellClass: "w-15",
        Cell: (props) => <>{props.value}</>,
      },

      {
        Header: "Actions",
        accessor: "action",
        cellClass: "w-10",
        Cell: (props) => <>{props.value}</>,
      },
    ],
    [allCities, filterLoading, paginationLoading, searchTrigger]
  );

  return (
    <>
      <Row>
        <Cols xxs="12">
          <div>
            {loading ? (
              <div
                className=" position-relative"
                style={{
                  height: "50vh",
                }}
              >
                <span className=" position-absolute loading"></span>
              </div>
            ) : emptyStatus ? (
              <Card>
                <div
                  className=" d-flex justify-content-center align-items-center text-danger fw-bold fs-5"
                  style={{
                    height: "200px",
                  }}
                >
                  <span>No Cities Found!</span>
                </div>
              </Card>
            ) : (
              <Card>
                <CardBody>
                  <Row className="d-flex justify-content-center">
                    <Cols lg="6">
                      <div className=" mt-2 d-flex justify-content-between flex-wrap">
                        <div className="mr-2 mb-2 position-relative">
                          <Input
                            disabled={
                              filterLoading ||
                              paginationLoading ||
                              createCitiesLoading
                            }
                            type="text"
                            name="keyword"
                            value={search}
                            id="search"
                            placeholder="Search"
                            onChange={(e) => {
                              handleSearch({
                                e,
                                setPageNumber,
                                setPageLimit,
                                setFilterLoading,
                                setSearch,
                                setSearchTrigger,
                                searchTrigger,
                              });
                            }}
                            style={{ minWidth: "200px" }}
                            className="rounded-lg"
                          />
                          {search.trim() && search.trim().length < 3 && (
                            <i
                              className="simple-icon-info pointer position-absolute text-danger fw-bold"
                              style={{ right: "-20px", bottom: "12px" }}
                              id={"SearchInfo"}
                            >
                              {" "}
                              <UncontrolledPopover
                                trigger="hover"
                                placement="top"
                                target={"SearchInfo"}
                              >
                                <PopoverBody className="text-center">
                                  <i>Minimum 3 words required!</i>
                                </PopoverBody>
                              </UncontrolledPopover>
                            </i>
                          )}
                          {search &&
                            (filterLoading && search.trim().length > 2 ? (
                              <Spinner
                                color="primary"
                                size={"sm"}
                                style={{ right: "10px", bottom: "12px" }}
                                className=" position-absolute"
                                type="grow"
                              ></Spinner>
                            ) : (
                              <span
                                className="far fa-close fs-6 position-absolute text-danger"
                                style={{
                                  right: "10px",
                                  bottom: "12px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  if (search) {
                                    setSearch("");

                                    setSearchTrigger(!searchTrigger);
                                    setFilterLoading(true);
                                  }
                                  pageNumber > 1 && setPageNumber(1);
                                  pageLimit > 50 && setPageLimit(50);
                                }}
                              ></span>
                            ))}
                        </div>

                        <div className="d-flex">
                          <div style={{ minWidth: "153px" }} className="mr-3">
                            <Select
                              hideSelectedOptions
                              isDisabled={
                                filterLoading ||
                                paginationLoading ||
                                createCitiesLoading
                              }
                              isClearable
                              value={sortingFilterValue}
                              onChange={(e) => {
                                setPaginationLoading(true);
                                if (e) {
                                  setSortingFilterValue(e);
                                  // handleSort(e);
                                } else {
                                  setSortingFilterValue("");
                                  // handleSort("");
                                }
                              }}
                              options={sortOptions}
                              placeholder="Sort Cities"
                            />
                          </div>
                          <div>
                            <Button
                              disabled={
                                filterLoading ||
                                paginationLoading ||
                                createCitiesLoading
                              }
                              size="sm"
                              className="float-right"
                              color="primary"
                              onClick={modalToggle}
                            >
                              Add Cities
                            </Button>
                          </div>
                        </div>
                      </div>
                      {paginationLoading && (
                        <div className="mt-3 mx-3">
                          <Alert color="info">
                            <Spinner
                              color="light"
                              size={"sm"}
                              style={{ marginBottom: "3px" }}
                            ></Spinner>{" "}
                            &nbsp;
                            <span style={{ fontSize: "16px", color: "black" }}>
                              Cities Loading!
                            </span>
                          </Alert>
                        </div>
                      )}

                      {filterLoading ? (
                        <div
                          className=" position-relative"
                          style={{
                            height: "200px",
                          }}
                        >
                          <span className=" position-absolute loading"></span>
                        </div>
                      ) : allCities?.length === 0 ? (
                        <div
                          className=" d-flex justify-content-center align-items-center text-danger"
                          style={{
                            height: "200px",
                          }}
                        >
                          <h3>
                            <b>No Cities Matched!</b>
                          </h3>
                        </div>
                      ) : (
                        <DataTable
                          setPageLimitInParent={setPageLimit}
                          setPageNumberInParent={setPageNumber}
                          fetchData={loadAllCities}
                          columns={cols || []}
                          data={currentPageData || []}
                          pageCount={totalPages}
                          setPaginationLoading={setPaginationLoading}
                          paginationLoading={paginationLoading}
                          selectedLength={0}
                        />
                      )}
                    </Cols>
                  </Row>
                </CardBody>
              </Card>
            )}
          </div>
        </Cols>
      </Row>

      <Modal isOpen={modal}>
        <ModalHeader toggle={modalToggle}>
          <h2 className="m-0 p-0">
            <b>Add New Cities</b>
          </h2>
        </ModalHeader>
        <ModalBody style={createCitiesLoading ? { opacity: "50%" } : {}}>
          <div>
            <FormGroup>
              <Label for="exampleEmail">City Name</Label>
              <Input
                className="rounded-lg"
                id="exampleEmail"
                name="city"
                value={city}
                placeholder="Enter Name"
                type="text"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleAddCity();
                  }
                }}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
              />
            </FormGroup>
            {city && (
              <Alert
                color={city.trim() ? "primary" : "danger"}
                className=" d-flex align-items-center"
                style={{ fontSize: "15px", cursor: "pointer" }}
                onClick={() => {
                  handleAddCity();
                }}
              >
                {city.trim() ? (
                  <>
                    <span
                      className="iconsminds-add mr-1"
                      // style={{ fontSize: '15px' }}
                    ></span>
                    <p className="m-0"> Add {` " ${city} "`}</p>
                  </>
                ) : (
                  "Please Enter Some Text!"
                )}
              </Alert>
            )}
          </div>
          <div className=" d-flex flex-wrap">
            {createdCities.map((c, i) => (
              <h4 className="mr-2 mt-1">
                <Badge color={"light"} key={i}>
                  <span style={{ textDecoration: "none" }} className="mr-2">
                    {toTitleCase(c)}
                  </span>

                  <span
                    style={{ cursor: "pointer" }}
                    className="far fa-close text-danger"
                    onClick={() => {
                      setCreatedCities(createdCities.filter((v) => v !== c));
                      // handleDeleteTags(c);
                    }}
                  ></span>
                </Badge>
              </h4>
            ))}
          </div>
        </ModalBody>
        <ModalFooter className=" justify-content-center">
          <Button
            color="danger"
            onClick={() => {
              modalToggle();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={createCitiesLoading || createdCities.length === 0}
            color="success"
            onClick={() => {
              handleCreateCities();
            }}
          >
            Submit
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={editCity?.value}>
        <ModalHeader toggle={modalEditToggle}>
          <h2 className="m-0 p-0">
            <b>Update City</b>
          </h2>
        </ModalHeader>
        <ModalBody>
          <div>
            <FormGroup>
              <Label for="exampleEmail">City Name</Label>
              <Input
                className="rounded-lg"
                name="city"
                value={editCity?.label}
                placeholder="Enter Name"
                type="text"
                onChange={(e) => {
                  setEditCity({
                    ...editCity,
                    label: e.target.value,
                  });
                }}
              />
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button
            color="danger"
            onClick={() => {
              modalEditToggle();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={
              !editCity?.label.trim() ||
              allCities.some(
                (obj) =>
                  obj._id === editCity?._id &&
                  obj.label === editCity?.label.trim()
              ) ||
              createCitiesLoading
            }
            color="success"
            onClick={() => {
              if (editCity.label.trim()) {
                handleUpdateCity();
              }
            }}
          >
            Update
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CitiesList;
