import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  Breadcrumb,
  Row,
  Table,
  Spinner,
  Alert,
  UncontrolledPopover,
  PopoverBody,
  CardBody,
  Input,
} from "reactstrap";
import { Cols } from "../SeparatorStyle/SeparatorStyle";
import { Link } from "react-router-dom";
import { getAllAccounts } from "../../api/accounts";
import { getStoreByUserId } from "../../api/stores";
import { getUserById, getAllUsers } from "../../api/users";
import { toast } from "react-toastify";
import DataTable from "../tables/table";
import Select from "react-select";
import { handleSearch } from "../../helpers/searchHook";

const Accounts = () => {
  const sortOptions = [
    { value: "ascending-name", label: "Name (A-Z)" },
    { value: "descending-name", label: "Name (Z-A)" },
    { value: "ascending-stores", label: "Stores (A-Z)" },
    { value: "descending-stores", label: "Stores (Z-A)" },
  ];

  const [total, setTotal] = useState("");
  const [pageLimit, setPageLimit] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [emptyStatus, setEmptyStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchTrigger, setSearchTrigger] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [sortingFilterValue, setSortingFilterValue] = useState("");

  const [allAccounts, setAllAccounts] = useState([]);
  const [storesNames, setStoresNames] = useState({ index: "", list: [] });

  const history = useHistory();

  const routeUserStore = (id) => {
    let path = `/admin/userStores/${id}`;
    console.log("Path: ", path);
    history.push(path);
  };

  // const loadAllAccounts = useCallback(async () => {
  //   setLoading(true);
  //   try {
  //     const { data } = await getAllAccounts();
  //     console.log('Accounts Data: ', data);
  //     setAccountsData(data);
  //     setLoading(false);
  //   } catch (error) {
  //     console.log('error found when fetch accounts data', error);
  //     setLoading(true);
  //   }
  // }, []);

  const storesList = (index, list) => {
    return (
      <UncontrolledPopover
        trigger="hover"
        placement="right"
        target={"Stores-" + Number(index)}
      >
        <PopoverBody>
          <ul style={{ listStyle: "none" }}>
            {list.map((v) => (
              <li>{v.shopDetail.name}</li>
            ))}
          </ul>
        </PopoverBody>
      </UncontrolledPopover>
    );
  };

  const loadAllAccounts = useCallback(
    async ({
      limit = pageLimit,
      page = pageNumber,
      searchValue = search.trim().length > 2 ? search : "",
      sorting = sortingFilterValue?.value || "",
    }) => {
      try {
        const { status, data } = await getAllAccounts({
          limit,
          page,
          searchValue,
          sorting,
        });
        setTotal(data.count);
        setAllAccounts(data.accountsData);
        if (status === 200) {
          if (data.count === 0 && !search && !sortingFilterValue) {
            setEmptyStatus(true);
          } else {
            setEmptyStatus(false);
          }
        }

        setLoading(false);
        setFilterLoading(false);
        setPaginationLoading(false);
      } catch (err) {
        toast.error("Accounts data loading error! ");
      }
    },
    [pageLimit, pageNumber, searchTrigger, sortingFilterValue]
  );
  const totalPages = Math.ceil(total / pageLimit);

  useEffect(() => {
    loadAllAccounts({});
  }, [loadAllAccounts]);

  // const handleSort = async (e) => {
  //   let sorting = [];
  //   setSortingFilterValue(e);

  //   if (!e) {
  //     sorting = allAccounts.sort((a, b) => b._id.localeCompare(a._id));
  //   } else {
  //     if (e?.value.includes("-name")) {
  //       if (e.value === "ascending-name") {
  //         sorting = allAccounts.sort((a, b) =>
  //           a?.userData[0]?.username
  //             .toLowerCase()
  //             .localeCompare(b?.userData[0]?.username?.toLowerCase())
  //         );
  //       } else {
  //         sorting = allAccounts.sort((a, b) =>
  //           b?.userData[0]?.username
  //             ?.toLowerCase()
  //             ?.localeCompare(a?.userData[0]?.username?.toLowerCase())
  //         );
  //       }
  //     } else if (e?.value.includes("-stores")) {
  //       if (e.value === "ascending-stores") {
  //         sorting = allAccounts.sort(
  //           (a, b) => a?.stores.length - b?.stores.length
  //         );
  //       } else {
  //         sorting = allAccounts.sort(
  //           (a, b) => b?.stores.length - a?.stores.length
  //         );
  //       }
  //     }
  //   }

  //   setAllAccounts([...sorting]);
  // };

  const currentPageData = allAccounts.map((obj, index) => {
    return {
      name: (
        <>
          {obj.userData[0]?.username?.slice(0, 20) || "N/A"}
          {obj.userData[0]?.username.length > 20 && (
            <>
              {" ... "}
              <i className="simple-icon-info pointer" id={"userName" + index}>
                <UncontrolledPopover
                  trigger="hover"
                  placement="top"
                  target={"userName" + index}
                >
                  <PopoverBody className="text-center">
                    {obj.userData[0]?.username}
                  </PopoverBody>
                </UncontrolledPopover>
              </i>
            </>
          )}
        </>
      ),
      status: obj.status || "N/A",
      stores: (
        <div>
          <span className="mr-2"> {obj.storesData.length}</span>
          {obj.storesData.length > 0 && (
            <span
              className="pointer simple-icon-info"
              onClick={() => {
                routeUserStore(obj._id);
              }}
              onMouseEnter={() => {
                setStoresNames({ ...storesNames, index, list: obj.storesData });
              }}
              onMouseLeave={() => {
                setStoresNames({ ...storesNames, index: "", list: [] });
              }}
              id={"Stores-" + index}
            >
              {index === storesNames.index &&
                storesList(storesNames.index, storesNames.list)}
            </span>
          )}
        </div>
      ),
      users: 1 || "N/A",
    };
  });

  const cols = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        cellClass: "w-15",
        Cell: (props) => <>{props.value}</>,
      },

      {
        Header: "Status",
        accessor: "status",
        cellClass: "w-15",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Stores",
        accessor: "stores",
        cellClass: "w-15",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Users",
        accessor: "users",
        cellClass: "w-15",
        Cell: (props) => <>{props.value}</>,
      },
    ],
    [allAccounts, filterLoading, paginationLoading, searchTrigger]
  );

  return (
    <>
      <main>
        <Row>
          <Cols xxs="12">
            <h1>Accounts</h1>
            <div style={{ display: "inline" }}>
              <Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
                <ol className="breadcrumb pt-0">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard" style={{ color: "black" }}>
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    All Accounts
                  </li>
                </ol>
              </Breadcrumb>
            </div>
          </Cols>
        </Row>

        <Row>
          <Cols xxs="12">
            <div>
              {loading ? (
                <div
                  className=" position-relative"
                  style={{
                    height: "50vh",
                  }}
                >
                  <span className=" position-absolute loading"></span>
                </div>
              ) : emptyStatus ? (
                <Card>
                  <div
                    className=" d-flex justify-content-center align-items-center text-danger font-weight-bold"
                    style={{
                      height: "200px",
                      fontSize: "20px",
                    }}
                  >
                    <span>No Accounts Found!</span>
                  </div>
                </Card>
              ) : (
                <Card>
                  <CardBody>
                    <div className=" mt-2 d-flex justify-content-between flex-wrap">
                      <div className="mr-2 mb-2 position-relative">
                        <Input
                          disabled={filterLoading || paginationLoading}
                          type="text"
                          name="keyword"
                          value={search}
                          id="search"
                          placeholder="Search"
                          onChange={(e) => {
                            handleSearch({
                              e,
                              setPageNumber,
                              setPageLimit,
                              setFilterLoading,
                              setSearch,
                              setSearchTrigger,
                              searchTrigger,
                            });
                          }}
                          style={{ minWidth: "200px" }}
                          className="rounded-lg"
                        />
                        {search.trim() && search.trim().length < 3 && (
                          <i
                            className="simple-icon-info pointer position-absolute text-danger fw-bold"
                            style={{ right: "-20px", bottom: "12px" }}
                            id={"SearchInfo"}
                          >
                            {" "}
                            <UncontrolledPopover
                              trigger="hover"
                              placement="top"
                              target={"SearchInfo"}
                            >
                              <PopoverBody className="text-center">
                                <i>Minimum 3 words required!</i>
                              </PopoverBody>
                            </UncontrolledPopover>
                          </i>
                        )}
                        {search &&
                          (filterLoading && search.trim().length > 2 ? (
                            <Spinner
                              color="primary"
                              size={"sm"}
                              style={{ right: "10px", bottom: "12px" }}
                              className=" position-absolute"
                              type="grow"
                            ></Spinner>
                          ) : (
                            <span
                              className="far fa-close fs-6 position-absolute text-danger"
                              style={{
                                right: "10px",
                                bottom: "12px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                if (search) {
                                  setSearch("");

                                  setSearchTrigger(!searchTrigger);
                                  setFilterLoading(true);
                                }
                                pageNumber > 1 && setPageNumber(1);
                                pageLimit > 50 && setPageLimit(50);
                              }}
                            ></span>
                          ))}
                      </div>

                      <div className="d-flex">
                        <div style={{ minWidth: "153px" }}>
                          <Select
                            hideSelectedOptions
                            isDisabled={filterLoading || paginationLoading}
                            isClearable
                            value={sortingFilterValue}
                            onChange={(e) => {
                              setPaginationLoading(true);
                              if (e) {
                                setSortingFilterValue(e);
                                // handleSort(e);
                              } else {
                                setSortingFilterValue("");
                                // handleSort("");
                              }
                            }}
                            options={sortOptions}
                            placeholder="Sort Filters"
                          />
                        </div>
                      </div>
                    </div>
                    {paginationLoading && (
                      <div className="mt-3 mx-3">
                        <Alert color="info">
                          <Spinner
                            color="light"
                            size={"sm"}
                            style={{ marginBottom: "3px" }}
                          ></Spinner>{" "}
                          &nbsp;
                          <span style={{ fontSize: "16px", color: "black" }}>
                            Accounts Loading!
                          </span>
                        </Alert>
                      </div>
                    )}

                    {filterLoading ? (
                      <div
                        className=" position-relative"
                        style={{
                          height: "200px",
                        }}
                      >
                        <span className=" position-absolute loading"></span>
                      </div>
                    ) : allAccounts?.length === 0 ? (
                      <div
                        className=" d-flex justify-content-center align-items-center text-danger"
                        style={{
                          height: "200px",
                        }}
                      >
                        <h3>
                          <b>No Accounts Matched!</b>
                        </h3>
                      </div>
                    ) : (
                      <DataTable
                        setPageLimitInParent={setPageLimit}
                        setPageNumberInParent={setPageNumber}
                        fetchData={loadAllAccounts}
                        columns={cols || []}
                        data={currentPageData || []}
                        pageCount={totalPages}
                        setPaginationLoading={setPaginationLoading}
                        paginationLoading={paginationLoading}
                        selectedLength={0}
                      />
                    )}
                  </CardBody>
                </Card>
              )}
            </div>
          </Cols>
        </Row>
      </main>
    </>
  );
};

export default Accounts;
