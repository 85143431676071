import axios from 'axios';
import { getCookie } from '../helpers/cookie';
import { getAPIURL } from './utils';
const url = getAPIURL();

// get All City Typos
export const getCityTypos = async ({ limit, page, title, sorting }) => {
  let token = getCookie();
  const params = new URLSearchParams();
  params.append('limit', limit?.toString());
  params.append('page', page?.toString());
  params.append('title', title?.toString().trim());
  params.append('sorting', sorting.toString());

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  };

  const response = await axios.get(`${url}/api/cityTypos`, config);
  return response;
};

// add new City Typo
export const saveCityTypos = async (data) => {
  const token = getCookie();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  let response;

  response = await axios.post(
    `${url}/api/cityTypos/createCityTypo`,
    data,
    config
  );

  return response;
};

// get City Typo by Name
export const getTyposByCity = async (cityId) => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${url}/api/cityTypos/getTyposByCity/${cityId}`,
    config
  );

  return response;
};

// Delete Typo
export const deleteCityTypos = async (data) => {
  const token = getCookie();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  let response;

  response = await axios.post(`${url}/api/cityTypos/deleteTypo`, data, config);

  return response;
};
