import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  Breadcrumb,
  Row,
  Spinner,
  Alert,
  UncontrolledPopover,
  PopoverBody,
  CardBody,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Cols } from "../SeparatorStyle/SeparatorStyle";
import { Link } from "react-router-dom";
import { getStoresByAccountId } from "../../api/stores";
import { toast } from "react-toastify";
import DataTable from "../tables/table";
import Select from "react-select";
import { handleSearch } from "../../helpers/searchHook";

const UserStores = () => {
  const { id } = useParams();

  const sortOptions = [
    { value: "ascending-storeName", label: "Stor Name (A-Z)" },
    { value: "descending-storeName", label: "Store Name (Z-A)" },
    { value: "ascending-owner", label: "Owner (A-Z)" },
    { value: "descending-owner", label: "Owner (Z-A)" },
  ];

  const [total, setTotal] = useState("");
  const [pageLimit, setPageLimit] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [emptyStatus, setEmptyStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchTrigger, setSearchTrigger] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [sortingFilterValue, setSortingFilterValue] = useState("");

  const [accountStores, setAccountStores] = useState([]);
  const [storeView, setStoreView] = useState({});

  const loadAccountStores = useCallback(
    async ({
      accountId = id,
      limit = pageLimit,
      page = pageNumber,
      searchValue = search.trim().length > 2 ? search : "",
      sorting = sortingFilterValue?.value || "",
    }) => {
      try {
        const { status, data } = await getStoresByAccountId({
          accountId,
          limit,
          page,
          searchValue,
          sorting,
        });
        setTotal(data.count);
        setAccountStores(data.storesData);
        if (status === 200) {
          if (data.count === 0 && !search && !sortingFilterValue) {
            setEmptyStatus(true);
          } else {
            setEmptyStatus(false);
          }
        }

        setLoading(false);
        setFilterLoading(false);
        setPaginationLoading(false);
      } catch (err) {
        toast.error("Accounts data loading error! ");
      }
    },
    [pageLimit, pageNumber, searchTrigger, sortingFilterValue]
  );
  const totalPages = Math.ceil(total / pageLimit);

  useEffect(() => {
    loadAccountStores({});
  }, [loadAccountStores]);

  const vewStoreModale = () => {
    setStoreView({});
  };

  // const handleSort = async (e) => {
  //   let sorting = [];
  //   setSortingFilterValue(e);

  //   if (!e) {
  //     sorting = accountStores.sort((a, b) => b._id.localeCompare(a._id));
  //   } else {
  //     if (e?.value.includes("-storeName")) {
  //       if (e?.value === "ascending-storeName") {
  //         sorting = accountStores.sort((a, b) =>
  //           a?.shopDetail.name
  //             .toLowerCase()
  //             .localeCompare(b?.shopDetail.name?.toLowerCase())
  //         );
  //       } else {
  //         sorting = accountStores.sort((a, b) =>
  //           b?.shopDetail.name
  //             ?.toLowerCase()
  //             ?.localeCompare(a?.shopDetail.name?.toLowerCase())
  //         );
  //       }
  //     } else if (e?.value.includes("-owner")) {
  //       if (e.value === "ascending-owner") {
  //         sorting = accountStores.sort((a, b) =>
  //           a?.shopDetail.shop_owner
  //             .toLowerCase()
  //             .localeCompare(b?.shopDetail.shop_owner?.toLowerCase())
  //         );
  //       } else {
  //         sorting = accountStores.sort((a, b) =>
  //           b?.shopDetail.shop_owner
  //             .toLowerCase()
  //             .localeCompare(a?.shopDetail.shop_owner?.toLowerCase())
  //         );
  //       }
  //     }
  //   }

  //   setAccountStores([...sorting]);
  // };

  const currentPageData = accountStores?.map((obj, index) => {
    return {
      storeName: obj.shopDetail.name || "N/A",
      owner:
        obj.shopDetail.shop_owner?.replace(obj.shopDetail.name, "") || "N/A",

      action: (
        <Button
          size="xs"
          color="primary"
          onClick={() => {
            setStoreView(obj);
          }}
        >
          View
        </Button>
      ),
    };
  });

  const cols = useMemo(
    () => [
      {
        Header: "Store Name",
        accessor: "storeName",
        cellClass: "w-15",
        Cell: (props) => <>{props.value}</>,
      },

      {
        Header: "Owner",
        accessor: "owner",
        cellClass: "w-15",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Action",
        accessor: "action",
        cellClass: "w-15",
        Cell: (props) => <>{props.value}</>,
      },
    ],
    [accountStores, filterLoading, paginationLoading, searchTrigger]
  );

  return (
    <>
      <main>
        <Row>
          <Cols xxs="12">
            <h1>Stores</h1>
            <div style={{ display: "inline" }}>
              <Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
                <ol className="breadcrumb pt-0">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard" style={{ color: "black" }}>
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    All Stores
                  </li>
                </ol>
              </Breadcrumb>
            </div>
          </Cols>
        </Row>

        <Row>
          <Cols xxs="12">
            <div>
              {loading ? (
                <div
                  className=" position-relative"
                  style={{
                    height: "50vh",
                  }}
                >
                  <span className=" position-absolute loading"></span>
                </div>
              ) : emptyStatus ? (
                <Card>
                  <div
                    className=" d-flex justify-content-center align-items-center text-danger font-weight-bold"
                    style={{
                      height: "200px",
                      fontSize: "20px",
                    }}
                  >
                    <span>No Accounts Found!</span>
                  </div>
                </Card>
              ) : (
                <Card>
                  <CardBody>
                    <div className=" mt-2 d-flex justify-content-between flex-wrap">
                      <div className="mr-2 mb-2 position-relative">
                        <Input
                          disabled={filterLoading || paginationLoading}
                          type="text"
                          name="keyword"
                          value={search}
                          id="search"
                          placeholder="Search"
                          onChange={(e) => {
                            handleSearch({
                              e,
                              setPageNumber,
                              setPageLimit,
                              setFilterLoading,
                              setSearch,
                              setSearchTrigger,
                              searchTrigger,
                            });
                          }}
                          style={{ minWidth: "200px" }}
                          className="rounded-lg"
                        />
                        {search.trim() && search.trim().length < 3 && (
                          <i
                            className="simple-icon-info pointer position-absolute text-danger fw-bold"
                            style={{ right: "-20px", bottom: "12px" }}
                            id={"SearchInfo"}
                          >
                            {" "}
                            <UncontrolledPopover
                              trigger="hover"
                              placement="top"
                              target={"SearchInfo"}
                            >
                              <PopoverBody className="text-center">
                                <i>Minimum 3 words required!</i>
                              </PopoverBody>
                            </UncontrolledPopover>
                          </i>
                        )}
                        {search &&
                          (filterLoading && search.trim().length > 2 ? (
                            <Spinner
                              color="primary"
                              size={"sm"}
                              style={{ right: "10px", bottom: "12px" }}
                              className=" position-absolute"
                              type="grow"
                            ></Spinner>
                          ) : (
                            <span
                              className="far fa-close fs-6 position-absolute text-danger"
                              style={{
                                right: "10px",
                                bottom: "12px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                if (search) {
                                  setSearch("");

                                  setSearchTrigger(!searchTrigger);
                                  setFilterLoading(true);
                                }
                                pageNumber > 1 && setPageNumber(1);
                                pageLimit > 50 && setPageLimit(50);
                              }}
                            ></span>
                          ))}
                      </div>

                      <div className="d-flex">
                        <div style={{ minWidth: "153px" }}>
                          <Select
                            hideSelectedOptions
                            isDisabled={filterLoading || paginationLoading}
                            isClearable
                            value={sortingFilterValue}
                            onChange={(e) => {
                              setPaginationLoading(true);
                              if (e) {
                                setSortingFilterValue(e);
                                // handleSort(e);
                              } else {
                                setSortingFilterValue("");
                                // handleSort("");
                              }
                            }}
                            options={sortOptions}
                            placeholder="Sort Filters"
                          />
                        </div>
                      </div>
                    </div>
                    {paginationLoading && (
                      <div className="mt-3 mx-3">
                        <Alert color="info">
                          <Spinner
                            color="light"
                            size={"sm"}
                            style={{ marginBottom: "3px" }}
                          ></Spinner>{" "}
                          &nbsp;
                          <span style={{ fontSize: "16px", color: "black" }}>
                            Accounts Loading!
                          </span>
                        </Alert>
                      </div>
                    )}

                    {filterLoading ? (
                      <div
                        className=" position-relative"
                        style={{
                          height: "200px",
                        }}
                      >
                        <span className=" position-absolute loading"></span>
                      </div>
                    ) : accountStores?.length === 0 ? (
                      <div
                        className=" d-flex justify-content-center align-items-center text-danger"
                        style={{
                          height: "200px",
                        }}
                      >
                        <h3>
                          <b>No Accounts Matched!</b>
                        </h3>
                      </div>
                    ) : (
                      <DataTable
                        setPageLimitInParent={setPageLimit}
                        setPageNumberInParent={setPageNumber}
                        fetchData={loadAccountStores}
                        columns={cols || []}
                        data={currentPageData || []}
                        pageCount={totalPages}
                        setPaginationLoading={setPaginationLoading}
                        paginationLoading={paginationLoading}
                        selectedLength={0}
                      />
                    )}
                  </CardBody>
                </Card>
              )}
            </div>
            <Modal isOpen={storeView?._id}>
              <ModalHeader toggle={vewStoreModale}>
                <b>{storeView?.shopDetail?.name}</b>
              </ModalHeader>
              <ModalBody>
                <div className="px-5">
                  <ul>
                    <li>
                      <div className="d-flex mb-3 justify-content-between flex-nowrap align-items-center">
                        <b>Owner Name-</b>
                        <span>{storeView?.shopDetail?.shop_owner}</span>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex mb-3 justify-content-between flex-nowrap align-items-center">
                        <b>Shop Domain-</b>
                        <span>{storeView?.shopDetail?.domain}</span>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex mb-3 justify-content-between flex-nowrap align-items-center">
                        <b>Owner Email-</b>
                        <span>{storeView?.shopDetail?.email}</span>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex mb-3 justify-content-between flex-nowrap align-items-center">
                        <b>Store Name-</b>
                        <span>{storeView?.shopDetail?.name}</span>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex mb-3 justify-content-between flex-nowrap align-items-center">
                        <b>Country-</b>
                        <span>{storeView?.shopDetail?.country_name}</span>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex mb-3 justify-content-between flex-nowrap align-items-center">
                        <b>City-</b>
                        <span>{storeView?.shopDetail?.city}</span>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex mb-3 justify-content-between flex-nowrap align-items-center">
                        <b>Zip Code-</b>
                        <span>{storeView?.shopDetail?.zip}</span>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex mb-3 justify-content-between flex-nowrap align-items-center">
                        <b>Address-</b>
                        <span>{storeView?.shopDetail?.address1}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </ModalBody>
              <ModalFooter className=" justify-content-center">
                <Button color="danger" onClick={vewStoreModale}>
                  Close
                </Button>
              </ModalFooter>
            </Modal>
          </Cols>
        </Row>
      </main>
    </>
  );
};

export default UserStores;
