import axios from "axios";
import { getCookie } from "../helpers/cookie";
import { getAPIURL } from "./utils";
const url = getAPIURL();

export const getAllAccounts = async ({ limit, page, searchValue, sorting }) => {
  let token = getCookie();
  const params = new URLSearchParams();
  params.append("limit", limit?.toString());
  params.append("page", page?.toString());
  params.append("search", searchValue?.toString().trim());
  params.append("sorting", sorting.toString());

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  };

  const response = await axios.get(
    `${url}/api/accounts/getAllAccounts`,
    config
  );
  return response;
};

// get Account by UserID
export const getAccountByUserId = async (id) => {
  const token = getCookie();
  const data = { _id: id };
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  let response;
  try {
    response = await axios.post(`${url}/api/accounts/id`, data, config);
    console.log("api response: ", response);
  } catch (e) {
    response = e;
  }

  return response;
};
