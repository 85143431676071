import React, { useEffect } from "react";
import Routing from "./routes/Routing";
import { ToastContainer } from "react-toastify";
import jwt_decode from "jwt-decode";
import { getCookie } from "./helpers/cookie";
// import { sessionExpired } from "./helpers/auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";

const App = () => {
  // let history = useHistory()

  useEffect(() => {
    // setInterval(() => {
    let token = getCookie();
    if (token) {
      let { exp } = jwt_decode(token);
      let currentDate = new Date().getTime();
      let changeInString = currentDate.toString();
      let sliceDate = changeInString.slice(0, 10);
      let convertInt = parseInt(sliceDate);
      if (convertInt >= exp) {
        toast.error("your session expired");
        localStorage.removeItem("user");
        localStorage.removeItem("userCouriers");
        Cookies.remove("token");
        window.location.href = "/";
        // history.push("/")
        // toast.error('your session expired')
      }
    }
    //   }, 5000);

    // let token = getCookie();
    // if (token) {
    //   let { exp } = jwt_decode(token);
    //   let currentDate = new Date().getTime()
    //   let changeInString = currentDate.toString()
    //   let sliceDate = changeInString.slice(0, 10)
    //   let convertInt = parseInt(sliceDate)
    //
    //   if (convertInt >= exp) {
    //     toast.error('your session expired')
    //     sessionExpired(() => { history.push("/signIn") });
    //   }
    // }
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick={false}
        pauseOnHover={false}
        draggable={true}
        progress={undefined}
      />
      <Routing />
    </>
  );
};

export default App;
