import React, { lazy, Suspense } from 'react';
import {
  Switch,
  Redirect,
  Route,
  BrowserRouter as Router,
} from 'react-router-dom';

// User Routes

// Admin Routes
import Dashboard from '../components/dashboards/Dashboard.jsx';
import CityAnyway from '../components/Cities/CityAnyway.jsx';
import CityTypos from '../components/Cities/CityTypos.jsx';
import Accounts from '../components/Accounts/Accounts.jsx';
import Users from '../components/Accounts/Users.jsx';
import UserStores from '../components/Accounts/UserStores.jsx';
import AccountSetting from '../components/Accounts/AccountSetting.jsx';
import MyCouriers from '../components/CouriersList/MyCouriers';
import UserAndPermissions from '../components/UserAndPermisions/UserAndPermisions';
import RegisteredUser from '../components/registeredUser/RegisteredUser.jsx';
import CitiesTabs from '../components/Cities/CitiesTabs.jsx';
//Authenticate Routing
const SignIn = lazy(() => import('../views/Signin.jsx'));
const Home = lazy(() => import('../views/Home.jsx'));
const SignupRecaptcha = lazy(() => import('../views/SignupRecaptcha.jsx'));
const Error = lazy(() => import('../views/Error.jsx'));
const ResetPassword = lazy(() => import('../views/ResetPassword.jsx'));
const NewPassword = lazy(() => import('../views/NewPassword.jsx'));
const AdminRoutes = lazy(() => import('./AdminRoutes'));
const UserRoutes = lazy(() => import('./UserRoutes'));

const Routing = () => {
  return (
    <>
      {/* {isMultiColorActive && <ColorSwitcher />} */}
      <Router>
        <Suspense fallback={<div className="" />}>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/signIn">
              <SignIn />
            </Route>
            <Route path="/signup">
              <SignupRecaptcha />
            </Route>
            <Route exact path="/requestForResetPassword">
              <ResetPassword />
            </Route>
            <Route path="/newPassword/:token">
              <NewPassword />
            </Route>
            <AdminRoutes
              exact
              path="/admin/myCouriers"
              component={MyCouriers}
            />
            <AdminRoutes
              exact
              path="/admin/userAndPermisions"
              component={UserAndPermissions}
            />
            <AdminRoutes exact path="/admin/dashboard" component={Dashboard} />
            {/* <AdminRoutes
              exact
              path="/admin/cityAnyway"
              component={CityAnyway}
            /> */}
            <AdminRoutes
              exact
              path="/admin/citiesManagement"
              component={CitiesTabs}
            />
            {/* <AdminRoutes exact path="/admin/cityTypos" component={CityTypos} /> */}
            <AdminRoutes
              exact
              path="/admin/registeredUsers"
              component={RegisteredUser}
            />
            <AdminRoutes exact path="/admin/accounts" component={Accounts} />
            <AdminRoutes
              exact
              path="/admin/account/personalSetting"
              component={AccountSetting}
            />
            <AdminRoutes
              exact
              path="/admin/userStores/:id"
              component={UserStores}
            />
            <AdminRoutes exact path="/admin/users" component={Users} />
            <Route path="/error">
              <Error />
            </Route>
            <Redirect exact to="/error" />
          </Switch>
        </Suspense>
      </Router>
    </>
  );
};

export default Routing;
